import dayjs from 'dayjs'
import queryString from 'query-string'

import { AIRCRAFT_TYPES, DATE_FORMAT } from '../../constants'
import { IState } from '../../types'
import { getTimes } from '../../utils'

export const queryToState = (initialState: IState): IState => {
  const queryParams = queryString.parse(window.location.search, {
    arrayFormat: 'comma',
  })

  const generatedState = { ...initialState }

  if (queryParams.aircraft_type) {
    if (typeof queryParams.aircraft_type === 'string') {
      const aircraftType = queryParams.aircraft_type.toUpperCase()

      if (AIRCRAFT_TYPES.includes(aircraftType)) {
        generatedState.aircraftType = aircraftType
      }
    }
  }

  if (queryParams.api_key) {
    if (typeof queryParams.api_key === 'string') {
      generatedState.apiKey = queryParams.api_key
      generatedState.activeModal = 'config'
    }
  }

  if (queryParams.bbox) {
    if (typeof queryParams.bbox === 'object') {
      const numericBbox = queryParams.bbox.map(Number)

      if (!numericBbox.some(isNaN)) {
        const [w, s, e, n] = numericBbox
        generatedState.bbox = { n, s, e, w }
      }
    }
  }

  if (queryParams.ef_threshold) {
    if (typeof queryParams.ef_threshold === 'string') {
      const numericThreshold = Number(queryParams.ef_threshold)

      if (!isNaN(numericThreshold)) {
        generatedState.efThreshold = numericThreshold
      }
    }
  }

  if (queryParams.gcontrails_threshold) {
    if (typeof queryParams.gcontrails_threshold === 'string') {
      const numericThreshold = Number(queryParams.gcontrails_threshold)

      if (!isNaN(numericThreshold)) {
        generatedState.gcontrailsThreshold = numericThreshold
      }
    }
  }

  if (queryParams.request) {
    if (typeof queryParams.request === 'string') {
      if (queryParams.request === 'true') {
        generatedState.autoRequestData = true
      }
    }
  }

  if (queryParams.start_time) {
    if (typeof queryParams.start_time === 'string') {
      const startTime = dayjs(queryParams.start_time)

      if (startTime.isValid()) {
        const times = getTimes(startTime)

        generatedState.timeline = {
          currentTime: startTime,
          times,
        }
      }
    }
  }

  return generatedState
}

export const stateToQuery = (state: IState): string => {
  const {
    apiKey,
    aircraftType,
    bbox,
    efThreshold,
    gcontrailsThreshold,
    timeline,
  } = state

  const { n, s, e, w } = bbox
  const queryState = {
    aircraft_type: aircraftType,
    api_key: apiKey,
    bbox: [w, s, e, n],
    ef_threshold: efThreshold,
    gcontrails_threshold: gcontrailsThreshold,
    start_time: timeline.times[0].format(DATE_FORMAT),
  }

  const currentQueryParams = queryString.parse(window.location.search, {
    arrayFormat: 'comma',
  })

  Object.keys(queryState).forEach((key) => {
    if (!(key in currentQueryParams)) {
      delete queryState[key]
    }
  })

  return queryString.stringify(queryState, {
    arrayFormat: 'comma',
    skipEmptyString: true,
    skipNull: true,
    sort: false,
  })
}
