import { IApiKeyAction, IState, TActions, TActionTypes } from '../../../types'

export const actionCreators = {
  setApiKey: (apiKey?: string): IApiKeyAction => ({
    type: TActionTypes.SET_API_KEY,
    apiKey,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_API_KEY: {
      return {
        ...state,
        apiKey: action.apiKey,
      }
    }
    default:
      return state
  }
}
