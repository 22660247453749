import React, { useEffect } from 'react'

import { DATE_FORMAT_WITH_ZONE, LOCAL_STORAGE } from '../../constants'
import { useAppContext } from '../../hooks'

export const LocalStorageManagement: React.FC = () => {
  const { state } = useAppContext()
  const { n, e, s, w } = state.bbox

  useEffect(() => {
    if (state.apiKey) {
      localStorage.setItem(LOCAL_STORAGE.API_KEY, state.apiKey)
    } else {
      localStorage.removeItem(LOCAL_STORAGE.API_KEY)
    }
  }, [state.apiKey])

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.BBOX, JSON.stringify({ e, n, s, w }))
  }, [n, e, s, w])

  useEffect(() => {
    const startTime = state.timeline.times[0]

    localStorage.setItem(
      LOCAL_STORAGE.START_DATE,
      startTime.format(DATE_FORMAT_WITH_ZONE)
    )
  }, [state.timeline.times])

  useEffect(() => {
    const levels = state.flightLevels

    localStorage.setItem(LOCAL_STORAGE.LEVELS, JSON.stringify(levels))
  }, [state.flightLevels])

  useEffect(() => {
    if (state.aircraftType) {
      localStorage.setItem(LOCAL_STORAGE.AIRCRAFT_TYPE, state.aircraftType)
    }
  }, [state.aircraftType])

  useEffect(() => {
    if (state.efThreshold) {
      localStorage.setItem(
        LOCAL_STORAGE.EF_THRESHOLD,
        String(state.efThreshold)
      )
    }
  }, [state.efThreshold])

  useEffect(() => {
    if (state.gcontrailsThreshold) {
      localStorage.setItem(
        LOCAL_STORAGE.G_CONTRAILS_THRESHOLD,
        String(state.gcontrailsThreshold)
      )
    }
  }, [state.gcontrailsThreshold])

  return null
}
