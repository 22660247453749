import axios from 'axios'

import { endpoints } from '../endpoints'
import { ICocipQueryParams, TCocipResponse } from '../../types'
import { exaggerateMultiPolygon } from '../../utils'
import { REQUEST_TIMEOUT } from '../../constants'

export interface ICocipApi {
  load: (params: ICocipQueryParams, apiKey: string) => Promise<TCocipResponse>
}

export const cocip: ICocipApi = {
  load: (params, apiKey) => {
    return axios({
      method: 'get',
      timeout: REQUEST_TIMEOUT,
      url: endpoints.cocip(params),
      headers: {
        'x-api-key': apiKey,
      },
    }).then(({ data }) => exaggerateMultiPolygon(data.features))
  },
}
