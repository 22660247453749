import {
  IGContrailsAction,
  IGContrailsThresholdAction,
  IState,
  TActions,
  TActionTypes,
  TGContrailsResponse,
  TGContrailsSingleResponse,
} from '../../../types'
import { newData } from '../../../utils'

export const actionCreators = {
  setGContrailsThreshold: (
    gcontrailsThreshold: number
  ): IGContrailsThresholdAction => ({
    type: TActionTypes.SET_G_CONTRAILS_THRESHOLD,
    gcontrailsThreshold,
  }),
  setGContrails: (
    time: string,
    data?: TGContrailsResponse
  ): IGContrailsAction => ({
    type: TActionTypes.SET_G_CONTRAILS,
    data,
    time,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_G_CONTRAILS_THRESHOLD: {
      return {
        ...state,
        gcontrailsThreshold: action.gcontrailsThreshold,
      }
    }
    case TActionTypes.SET_G_CONTRAILS: {
      return {
        ...state,
        gcontrails: {
          ...state.gcontrails,
          [action.time]: newData<TGContrailsSingleResponse>(
            action.data,
            state.gcontrails[action.time]
          ),
        },
      }
    }
    default:
      return state
  }
}
