import queryString from 'query-string'
import { useContext, useEffect, useRef } from 'react'
import { stateToQuery } from '../state/query'

import { Context, SetViewstateContext, ViewstateContext } from '../state/store'

export const useAppContext = () => useContext(Context)

export const useAppUtilities = () => {
  const { state } = useAppContext()
  const queryParams = useParams()

  const cocipData = Object.keys(state.cocip)
  const pcrData = Object.keys(state.pcr)
  const gcontrailsData = Object.keys(state.gcontrails)
  const data = [cocipData, pcrData, gcontrailsData]
  const timesCount = state.timeline.times.length

  const slicesDownloaded =
    cocipData.length + pcrData.length + gcontrailsData.length

  let layersLoaded = 0
  let layersToLoad = 1

  data.forEach((layer) => {
    if (layer.length === timesCount) {
      layersLoaded += 1
    }
  })

  layersToLoad += layersLoaded

  if (queryParams.gcontrails_threshold) {
    layersToLoad += 1
  }

  return {
    slicesDownloaded,
    slicesTotal: layersToLoad * timesCount,
  }
}

export const useQueryString = () => {
  const { state } = useAppContext()
  const { host, protocol } = window.location

  const queryString = stateToQuery(state)
  const stateUrl = `${protocol}//${host}?${queryString}`

  return { queryString, stateUrl }
}

export const useParams = () => {
  const queryParams = queryString.parse(window.location.search, {
    arrayFormat: 'comma',
  })

  return { ...queryParams }
}

export const useViewstate = () => {
  const ctx = useContext(ViewstateContext)
  if (!ctx) throw Error('Not wrapped in <ViewstateProvider />.')
  return ctx
}

export const useSetViewstate = () => {
  const ctx = useContext(SetViewstateContext)
  if (!ctx) throw Error('Not wrapped in <ViewstateProvider />.')
  return ctx
}

const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useEffectDebugger = (
  effectHook,
  dependencies,
  dependencyNames = []
) => {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      }
    }

    return accum
  }, {})

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies)
}
