import React, { useEffect } from 'react'

import { useQueryString } from '../../hooks'

export const QueryStringManagement: React.FC = () => {
  const { stateUrl } = useQueryString()

  useEffect(() => {
    if (window.location.search) {
      window.history.pushState({}, '', stateUrl)
    }
  }, [stateUrl])

  return null
}
