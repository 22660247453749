import React, { useState } from 'react'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Image,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from 'antd'
import {
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { Position } from '@turf/turf'

import { actionCreators } from '../../state/reducer'
import { AIRCRAFT_TYPES } from '../../constants'
import { API } from '../../api'
import { IconButton } from '../IconButton'
import { LabeledField } from '../LabeledField'
import { TAggregatedPathData } from '../../types'
import { buildFlightPath } from '../../utils'
import { useAppContext } from '../../hooks'
import './FlightPlanModal.css'

interface IProps {
  setCoordinates: React.Dispatch<React.SetStateAction<Position[] | undefined>>
  setFlightPath: React.Dispatch<
    React.SetStateAction<TAggregatedPathData | undefined>
  >
}

export const FlightPlanModal: React.FC<IProps> = ({
  setCoordinates,
  setFlightPath,
}) => {
  const { state, dispatch } = useAppContext()

  const [visible, setVisible] = useState(false)
  const [componentFlightPlan, setComponentFlightPlan] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [componentDate, setComponentDate] = useState<dayjs.Dayjs | null>(
    dayjs()
  )
  const [componentAircraftType, setComponentAircraftType] = useState<string>()
  const [verticalProfile, setVerticalProfile] = useState<string | undefined>()
  const [minimized, setMinimized] = useState(false)
  const [flightPlanController, setFlightPlanController] = useState(
    new AbortController()
  )
  const [verticalProfileController, setVerticalProfileController] = useState(
    new AbortController()
  )

  const handleFlightPlanChange = ({ target }: React.SyntheticEvent) => {
    setError(undefined)
    setComponentFlightPlan((target as HTMLInputElement).value)
  }

  const handleTimeChange = (v: dayjs.Dayjs | null) => {
    setError('')
    if (!v) {
      setComponentDate(v)
    } else {
      const utc = v.utc()
      setComponentDate(utc)
    }
  }

  const disabled =
    !componentDate || !componentFlightPlan || !componentAircraftType

  const handleSubmit = async () => {
    if (disabled) return
    if (!state.apiKey) return

    setError(undefined)
    setLoading(true)
    dispatch(actionCreators.setEditMode('view'))

    const {
      coordinates,
      flightData,
      error: flightDataError,
    } = await API.flightPlan.load(
      componentFlightPlan,
      state.apiKey,
      flightPlanController.signal
    )

    if (flightDataError) {
      setLoading(false)
      setError(flightDataError)
      return
    }

    if (coordinates) {
      setCoordinates(coordinates)
      const path = buildFlightPath(
        coordinates,
        state.cocip,
        state.timeline.times
      )
      setFlightPath(path)
    }

    if (flightData) {
      const params = {
        aircraftType: componentAircraftType,
        time: componentDate,
        flightData,
      }
      const { data, error: profileError } = await API.verticalProfile.load(
        params,
        state.apiKey,
        verticalProfileController.signal
      )

      if (profileError) {
        setLoading(false)
        setError(profileError)
        return
      }

      if (data) {
        setLoading(false)
        setVerticalProfile(data.png)
      }
    }
  }

  if (!visible) {
    return (
      <div className="flight-plan-link">
        <Button onClick={() => setVisible(true)}>Flight Plan</Button>
      </div>
    )
  }

  const chartClass = verticalProfile
    ? 'chart-container-expanded'
    : 'chart-container'

  const reset = () => {
    if (loading) {
      flightPlanController.abort()
      verticalProfileController.abort()
      setFlightPlanController(new AbortController())
      setVerticalProfileController(new AbortController())
    }

    setVerticalProfile(undefined)
    setError(undefined)
    setMinimized(false)
    setLoading(false)
  }

  return (
    <div className="card-container">
      <Card
        extra={
          <Space style={{ display: 'flex', flexDirection: 'row' }}>
            {minimized ? (
              <IconButton
                icon={<PlusOutlined />}
                onClick={() => setMinimized(false)}
                tooltipText="Expand"
              />
            ) : (
              <IconButton
                icon={<MinusOutlined />}
                onClick={() => setMinimized(true)}
                tooltipText="Collapse"
              />
            )}
            <IconButton
              icon={<ReloadOutlined />}
              onClick={reset}
              tooltipText="Start over"
            />
            <IconButton
              icon={<CloseOutlined />}
              onClick={() => setVisible(false)}
              tooltipText="Close"
            />
          </Space>
        }
        actions={
          loading || verticalProfile || minimized
            ? []
            : [
                <div className="flight-plan-actions">
                  <Button
                    disabled={disabled}
                    loading={loading}
                    onClick={handleSubmit}
                    type="primary"
                  >
                    Submit
                  </Button>
                </div>,
              ]
        }
        className="flight-plan-container"
        size="small"
        bodyStyle={loading || minimized ? { padding: 0 } : {}}
        headStyle={loading || minimized ? { borderBottom: 'none' } : {}}
        style={{ width: 500 }}
        title={
          <Space style={{ display: 'flex', flexDirection: 'row' }}>
            <p>Visualize Flight Plan</p>
            {loading && <Spin />}
          </Space>
        }
      >
        {loading || verticalProfile || minimized ? null : (
          <>
            <LabeledField label="Flight Plan" errorText={error}>
              <Input.TextArea
                onChange={handleFlightPlanChange}
                placeholder="Enter your waypoints here"
                status={error ? 'error' : ''}
                value={componentFlightPlan}
              />
            </LabeledField>
            <Row>
              <Col span={11}>
                <LabeledField label="Departure Date">
                  <DatePicker
                    format="YYYY-MM-DD"
                    onChange={handleTimeChange}
                    showNow={true}
                    style={{ width: '100%' }}
                    value={componentDate}
                  />
                </LabeledField>
              </Col>
              <Col span={11} offset={2}>
                <LabeledField label="Aircraft Type">
                  <Select
                    style={{ width: '100%' }}
                    onChange={(v) => setComponentAircraftType(v)}
                    options={AIRCRAFT_TYPES.map((type) => ({
                      value: type,
                      label: type,
                    }))}
                    value={componentAircraftType}
                  />
                </LabeledField>
              </Col>
            </Row>
          </>
        )}
        {minimized ? null : (
          <div className={chartClass}>
            {verticalProfile && <Image src={verticalProfile} width="95%" />}
          </div>
        )}
      </Card>
    </div>
  )
}
