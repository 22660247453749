import axios from 'axios'
import dayjs from 'dayjs'

import { endpoints } from '../endpoints'
import { findDistance } from './utils'
import { IFlightData, IFlightDataPayload } from '../../types'
import { REQUEST_CANCELED } from '../../constants'

interface IResponseSuccess {
  data: {
    png: string
    arrivalTime: dayjs.Dayjs
  }
  error?: never
}

interface IResponseFailure {
  data?: never
  error: string
}

type TResponse = IResponseSuccess | IResponseFailure

export interface IParams {
  aircraftType?: string
  time: dayjs.Dayjs
  flightData: IFlightDataPayload
}

export interface IVerticalProfileApi {
  load: (
    params: IParams,
    apiKey: string,
    signal: AbortSignal
  ) => Promise<TResponse>
}

export const verticalProfile: IVerticalProfileApi = {
  load: async (params, apiKey, signal) => {
    const { aircraftType, time, flightData } = params

    const requestPayload: IFlightData = flightData

    // interpolate times
    const dt = requestPayload.latitude.map((lat2, idx) => {
      if (idx === 0) {
        return 0
      } else {
        const lon1 = requestPayload.longitude[idx - 1]
        const lat1 = requestPayload.latitude[idx - 1]
        const lon2 = requestPayload.longitude[idx]
        return Math.round(findDistance(lat1, lon1, lat2, lon2) / 235) // 235 m/s ~ 525 mph
      }
    })
    dt[0] = dt[1] // set the 0th element to the first for now

    // unix (s) values
    const cumulativeSum = (
      (sum) => (value) =>
        (sum += value)
    )(time.unix())
    requestPayload.time = dt.map(cumulativeSum)
    requestPayload.aircraft_type = aircraftType
    requestPayload.format = 'png'
    requestPayload.mode = 'grid'
    requestPayload._skip_validate = 'True'

    try {
      const response = await axios({
        method: 'post',
        timeout: 600 * 1000,
        url: endpoints.verticalProfile(),
        headers: {
          'x-api-key': apiKey,
        },
        data: requestPayload,
        responseType: 'arraybuffer',
        signal,
      })
      const blob = new Blob([response.data], { type: 'image/png' })
      const png = URL.createObjectURL(blob)

      const data = {
        arrivalTime: dayjs.unix(requestPayload.time.slice(-1)[0]).utc(),
        png,
      }

      return {
        data,
      }
    } catch (error: any) {
      if (error.message === 'canceled') {
        return { error: REQUEST_CANCELED }
      }

      if (error.response?.data) {
        const decoder = new TextDecoder()
        const decodedError = decoder.decode(error.response.data)
        const errorData = JSON.parse(decodedError)

        return {
          error: errorData.detail.msg,
        }
      }

      return {
        error: 'Vertical profile could not be generated',
      }
    }
  },
}
