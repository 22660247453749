import { RADIUS_K } from '../../../constants'

// convert degrees to radians
function deg2rad(deg: number): number {
  return (deg * Math.PI) / 180 // radians = degrees * pi/180
}

// Haversine functions
export function findDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  let t1: number
  let n1: number
  let t2: number
  let n2: number
  let dlat: number
  let dlon: number
  let a: number
  let c: number
  let dk: number

  // get values for lat1, lon1, lat2, and lon2
  t1 = lat1
  n1 = lon1
  t2 = lat2
  n2 = lon2

  // convert coordinates to radians
  lat1 = deg2rad(t1)
  lon1 = deg2rad(n1)
  lat2 = deg2rad(t2)
  lon2 = deg2rad(n2)

  // find the differences between the coordinates
  dlat = lat2 - lat1
  dlon = lon2 - lon1

  // here's the heavy lifting
  a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2)
  c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) // great circle distance in radians
  dk = c * RADIUS_K * 1000 // great circle distance in m

  // round the results down to the nearest 1/1000
  return Math.round(dk)
}
