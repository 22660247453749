import {
  IAddLayerOptionAction,
  IEnabledLayersAction,
  ILayerOptionsAction,
  IState,
  TActionTypes,
  TActions,
  TLayer,
} from '../../../types'

export const actionCreators = {
  addLayerOption: (layer: TLayer): IAddLayerOptionAction => ({
    type: TActionTypes.ADD_LAYER_OPTION,
    layer,
  }),
  setEnabledLayers: (layers: TLayer[]): IEnabledLayersAction => ({
    type: TActionTypes.SET_ENABLED_LAYERS,
    layers,
  }),
  setLayerOptions: (layers: TLayer[]): ILayerOptionsAction => ({
    type: TActionTypes.SET_LAYER_OPTIONS,
    layers,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.ADD_LAYER_OPTION: {
      const options = [...state.layers.options]
      if (!state.layers.options.includes(action.layer)) {
        options.push(action.layer)
      }

      return {
        ...state,
        layers: {
          ...state.layers,
          options,
        },
      }
    }
    case TActionTypes.SET_ENABLED_LAYERS: {
      const uniqueLayers = [...new Set(action.layers)]

      return {
        ...state,
        layers: {
          ...state.layers,
          enabled: uniqueLayers,
        },
      }
    }
    case TActionTypes.SET_LAYER_OPTIONS: {
      const uniqueLayers = [...new Set(action.layers)]

      return {
        ...state,
        layers: {
          ...state.layers,
          options: uniqueLayers,
        },
      }
    }
    default:
      return state
  }
}
