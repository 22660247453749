import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../../constants'
import {
  ICurrentTimeAction,
  IState,
  ITimesAction,
  TActionTypes,
  TActions,
} from '../../../types'

export const actionCreators = {
  setCurrentTime: (currentTime: string): ICurrentTimeAction => ({
    type: TActionTypes.SET_CURRENT_TIME,
    currentTime: dayjs(currentTime),
  }),
  setTimeline: (newTimes: dayjs.Dayjs[]): ITimesAction => ({
    type: TActionTypes.SET_TIMES,
    newTimes,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_CURRENT_TIME: {
      return {
        ...state,
        timeline: {
          ...state.timeline,
          currentTime: action.currentTime,
        },
      }
    }
    case TActionTypes.SET_TIMES: {
      const newTimes = action.newTimes
      const newTimeValues = newTimes.map((time) => time.valueOf())
      const intersection = state.timeline.times.filter((existingTime) =>
        newTimeValues.includes(existingTime.valueOf())
      )
      const intersectionStrings = intersection.map((time) =>
        time.format(DATE_FORMAT)
      )
      const newCocip = { ...state.cocip }
      const newGcontrails = { ...state.gcontrails }

      Object.keys(newCocip).forEach((time) => {
        if (!intersectionStrings.includes(time)) {
          delete newCocip[time]
        }
      })

      Object.keys(newGcontrails).forEach((time) => {
        if (!intersectionStrings.includes(time)) {
          delete newGcontrails[time]
        }
      })

      return {
        ...state,
        cocip: newCocip,
        gcontrails: newGcontrails,
        timeline: {
          times: newTimes,
          currentTime: newTimes[0],
        },
      }
    }

    default:
      return state
  }
}
