import axios from 'axios'

import { endpoints } from '../endpoints'
import { IGContrailsQueryParams, TGContrailsResponse } from '../../types'
import { exaggerateMultiPolygon } from '../../utils'
import { REQUEST_TIMEOUT } from '../../constants'

export interface IGContrailsApi {
  load: (
    params: IGContrailsQueryParams,
    apiKey: string
  ) => Promise<TGContrailsResponse>
}

export const gcontrails: IGContrailsApi = {
  load: (params, apiKey) => {
    return axios({
      method: 'get',
      timeout: REQUEST_TIMEOUT,
      url: endpoints.gcontrails(params),
      headers: {
        'x-api-key': apiKey,
      },
    }).then(({ data }) => exaggerateMultiPolygon(data.features))
  },
}
