import React, { useMemo } from 'react'
import { Slider } from 'antd'
import type { SliderMarks } from 'antd/es/slider'

import { actionCreators } from '../../state/reducer'
import { DATE_FORMAT } from '../../constants'
import { TimelineLabel } from './Label'
import { useAppContext } from '../../hooks'
import './Timeline.css'

export const Timeline: React.FC = () => {
  const { dispatch, state } = useAppContext()

  const marks = useMemo(() => {
    const sliderMarks: SliderMarks = {}

    state.timeline.times.forEach((time, index) => {
      sliderMarks[index] = {
        label: <TimelineLabel time={time} index={index} />,
      }
    })

    return sliderMarks
  }, [state.timeline.times])

  const formatter = (value?: number) => {
    if (value === undefined) return 'Invalid time'
    if (!state.cocip[state.timeline.times[value].format(DATE_FORMAT)]) {
      return 'No data available'
    }

    return state.timeline.times[value].format(DATE_FORMAT)
  }

  const handleChange = (value: number) => {
    const newTime = state.timeline.times[value]

    dispatch(actionCreators.setCurrentTime(newTime.format(DATE_FORMAT)))
  }

  return (
    <div className="timeline-container">
      <Slider
        defaultValue={0}
        included={false}
        marks={marks}
        max={state.timeline.times.length - 1}
        min={0}
        onChange={handleChange}
        tooltip={{ formatter }}
      />
    </div>
  )
}
