import dayjs from 'dayjs'
import React from 'react'
import { DATE_FORMAT } from '../../constants'
import { useAppContext } from '../../hooks'

interface IProps {
  index: number
  time: dayjs.Dayjs
}

export const TimelineLabel: React.FC<IProps> = ({ time, index }) => {
  const { state } = useAppContext()

  const month = time.format('MMM')
  const day = time.format('DD')
  const hour = time.format('HH')

  const outerLabelClasses: string[] = ['slider-label', 'horizontal-slider']
  const innerLabelClasses: string[] = []

  const timeKey = time.format(DATE_FORMAT)

  const cocipPresent =
    timeKey in state.cocip && state.cocip[timeKey] !== undefined
  const pcrPresent = timeKey in state.pcr && state.pcr[timeKey] !== undefined

  const cocipError =
    timeKey in state.cocip && state.cocip[timeKey] === undefined
  const pcrError = timeKey in state.pcr && state.pcr[timeKey] === undefined
  const errorPresent = cocipError || pcrError

  const gcontrailsPresent =
    timeKey in state.gcontrails && state.gcontrails[timeKey] !== undefined

  if (cocipPresent || pcrPresent || errorPresent || gcontrailsPresent) {
    outerLabelClasses.push('active')
  }

  if (cocipPresent) {
    innerLabelClasses.push('slider-cocip')
  }

  if (pcrPresent) {
    innerLabelClasses.push('slider-pcr')
  }

  if (errorPresent) {
    innerLabelClasses.push('slider-error')
  }

  if (gcontrailsPresent) {
    innerLabelClasses.push('slider-google')
  }

  let showDayLabel = false

  if (index === 0) {
    showDayLabel = true
  } else {
    const previousTime = state.timeline.times[index - 1]

    if (previousTime.date() !== time.date()) {
      showDayLabel = true
    }
  }

  return (
    <div className={outerLabelClasses.join(' ')}>
      <div className={innerLabelClasses.join(' ')}>
        <div>{`${hour}Z`}</div>
        {showDayLabel && (
          <div className="day-label">
            <strong>{`${month} ${day}`}</strong>
          </div>
        )}
      </div>
    </div>
  )
}
