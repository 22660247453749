import {
  ICocipAction,
  IState,
  TActions,
  TActionTypes,
  TCocipResponse,
  TCocipSingleResponse,
} from '../../../types'
import { newData } from '../../../utils'

export const actionCreators = {
  setCocip: (time: string, data?: TCocipResponse): ICocipAction => ({
    type: TActionTypes.SET_COCIP,
    data,
    time,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_COCIP: {
      return {
        ...state,
        cocip: {
          ...state.cocip,
          [action.time]: newData<TCocipSingleResponse>(
            action.data,
            state.cocip[action.time]
          ),
        },
      }
    }
    default:
      return state
  }
}
