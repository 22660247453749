import dayjs from 'dayjs'
import { booleanPointInPolygon, polygon, Position } from '@turf/turf'

import {
  ICoordinates,
  TCocipResponse,
  TGenericMultiPolygon,
  ITimeline,
  TBBox,
  TAggregatedPathData,
  TPcrResponse,
} from '../types'
import {
  DATE_FORMAT,
  DEFAULTS,
  FEET_PER_METER,
  INTERSECT_RGB,
  NO_INTERSECT_RGB,
} from '../constants'
import { exaggerate } from './exaggerate'

export const findBoundingBoxCenter = (bbox: TBBox): ICoordinates => {
  const { n, s, e, w } = bbox

  return {
    latitude: s + (n - s) / 2,
    longitude: w + (e - w) / 2,
  }
}

export const createBoundingBoxFromPoint = (
  coordinates: [number, number] // longitude, latitude
): TBBox => {
  const [longitude, latitude] = coordinates
  const defaultWidth = DEFAULTS.BBOX.E - DEFAULTS.BBOX.W
  const defaultHeight = DEFAULTS.BBOX.N - DEFAULTS.BBOX.S

  const n = Math.floor(latitude + defaultHeight / 2)
  const s = Math.floor(latitude - defaultHeight / 2)
  const w = Math.floor(longitude - defaultWidth / 2)
  const e = Math.floor(longitude + defaultWidth / 2)

  return {
    n: n > 90 ? 90 : n,
    s: s < -90 ? -90 : s,
    w: w < -180 ? -180 : w,
    e: e > 180 ? 180 : e,
  }
}

export const getTimes = (startTime: dayjs.Dayjs): dayjs.Dayjs[] => {
  return new Array(DEFAULTS.NUMBER_OF_TIMES).fill(0).map((_, x) => {
    return startTime.add(x, 'hours')
  })
}

export const createTimeline = (startTime: dayjs.Dayjs): ITimeline => ({
  currentTime: startTime,
  times: getTimes(startTime),
})

export const roundToHour = (initialTime: dayjs.Dayjs): dayjs.Dayjs => {
  return initialTime.minute(0).second(0).millisecond(0)
}

export const exaggerateMultiPolygon = (features: TGenericMultiPolygon) => {
  features.forEach((feature) => {
    const { geometry } = feature
    const { coordinates } = geometry

    exaggerateMultiline(coordinates)
  })

  return features
}

const exaggerateMultiline = (coordinates: Position[][][]) => {
  coordinates.forEach((polygon) => {
    polygon.forEach((polygonCoordinates) => {
      polygonCoordinates.forEach((coordinateSet) => {
        coordinateSet[2] = exaggerate(coordinateSet[2])
      })
    })
  })
}

export const exaggerateLine = (coordinates: Position[]) => {
  coordinates.forEach((coordinateSet) => {
    coordinateSet[2] = exaggerate(coordinateSet[2] / FEET_PER_METER)
  })
}

export const newData = <T>(
  addlData: T[] | undefined,
  existingData: T[] | undefined
): T[] | undefined => {
  const existing = existingData ?? []
  const adding = addlData ?? []

  const response = [...existing, ...adding]

  if (response.length === 0) {
    return undefined
  }

  return response
}

export const buildFlightPath = (
  coordinates: Position[],
  geojson: Record<string, TCocipResponse | TPcrResponse | undefined>,
  times: dayjs.Dayjs[]
): TAggregatedPathData => {
  const data: TAggregatedPathData = {}

  if (Object.keys(geojson).length === 0) {
    times.forEach((dayjsTime) => {
      const time = dayjsTime.format(DATE_FORMAT)

      data[time] = [
        {
          path: coordinates,
          name: 'No Intersect',
          color: NO_INTERSECT_RGB,
        },
      ]
    })

    return data
  }

  coordinates.forEach((trajectoryVertex) => {
    Object.entries(geojson).forEach(([time, geojsonTimeEntry]) => {
      let intersects = false

      if (geojsonTimeEntry) {
        intersects = geojsonTimeEntry.some((geojsonMultipolygon) => {
          const currentDataLevel = exaggerate(
            (geojsonMultipolygon.properties.level * 100) / FEET_PER_METER
          )
          const nextDataLevel = exaggerate(
            ((geojsonMultipolygon.properties.level + 10) * 100) / FEET_PER_METER
          )
          const trajectoryWithinFlightLevel =
            trajectoryVertex[2] >= currentDataLevel &&
            trajectoryVertex[2] < nextDataLevel

          if (!trajectoryWithinFlightLevel) return false

          // normalize trajectoryVertex to nearest 1000 ft
          // to put it in the same 2d space as geojson region
          const normalizedVertex = [
            trajectoryVertex[0],
            trajectoryVertex[1],
            currentDataLevel,
          ]

          return geojsonMultipolygon.geometry.coordinates.some(
            (geojsonPolygon) => {
              // booleanPointInPolygon only finds point in first polygon of a multipolygon
              // https://github.com/Turfjs/turf/issues/1724
              return geojsonPolygon.some((polygonCoords) => {
                const newpolygon = polygon([polygonCoords])
                return booleanPointInPolygon(normalizedVertex, newpolygon)
              })
            }
          )
        })
      }

      if (time in data) {
        const lastRecord = data[time][data[time].length - 1]
        const intersecting = lastRecord.name === 'Intersect'

        if (intersecting) {
          if (intersects) {
            lastRecord.path.push(trajectoryVertex)
          } else {
            data[time].push({
              path: [
                lastRecord.path[lastRecord.path.length - 1],
                trajectoryVertex,
              ],
              name: 'No Intersect',
              color: NO_INTERSECT_RGB,
            })
          }
        } else {
          if (intersects) {
            lastRecord.path.push(trajectoryVertex)
            data[time].push({
              path: [trajectoryVertex],
              name: 'Intersect',
              color: INTERSECT_RGB,
            })
          } else {
            lastRecord.path.push(trajectoryVertex)
          }
        }
      } else {
        if (intersects) {
          data[time] = [
            {
              path: [trajectoryVertex],
              name: 'Intersect',
              color: INTERSECT_RGB,
            },
          ]
        } else {
          data[time] = [
            {
              path: [trajectoryVertex],
              name: 'No Intersect',
              color: NO_INTERSECT_RGB,
            },
          ]
        }
      }
    })
  })

  return data
}
