import {
  ISetEditModeAction,
  IState,
  TActionTypes,
  TActions,
  TMode,
} from '../../../types'

export const actionCreators = {
  setEditMode: (editMode: TMode): ISetEditModeAction => ({
    type: TActionTypes.SET_BBOX_EDIT_MODE,
    editMode,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_BBOX_EDIT_MODE: {
      return {
        ...state,
        editMode: action.editMode,
      }
    }
    default:
      return state
  }
}
