import {
  IEfThresholdAction,
  IState,
  TActions,
  TActionTypes,
} from '../../../types'

export const actionCreators = {
  setEfThreshold: (efThreshold: number): IEfThresholdAction => ({
    type: TActionTypes.SET_EF_THRESHOLD,
    efThreshold,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_EF_THRESHOLD: {
      return {
        ...state,
        efThreshold: action.efThreshold,
      }
    }
    default:
      return state
  }
}
