import {
  IBboxAction,
  IState,
  TActionTypes,
  TActions,
  TBBox,
} from '../../../types'

export const actionCreators = {
  setBbox: (bbox: TBBox): IBboxAction => ({
    type: TActionTypes.SET_BBOX,
    bbox,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_BBOX: {
      return {
        ...state,
        bbox: action.bbox,
      }
    }
    default:
      return state
  }
}
