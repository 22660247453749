import {
  IFlightLevelsAction,
  IState,
  TActions,
  TActionTypes,
} from '../../../types'

export const actionCreators = {
  setFlightLevels: (flightLevels: [number, number]): IFlightLevelsAction => ({
    type: TActionTypes.SET_FLIGHT_LEVELS,
    flightLevels,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_FLIGHT_LEVELS: {
      return {
        ...state,
        flightLevels: action.flightLevels,
      }
    }
    default:
      return state
  }
}
