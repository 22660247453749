import React from 'react'
import { Slider } from 'antd'
import type { SliderMarks } from 'antd/es/slider'

import { useSetViewstate, useViewstate } from '../../hooks'
import './PitchSlider.css'

export const PitchSlider = () => {
  const viewState = useViewstate()
  const setViewState = useSetViewstate()

  const marks: SliderMarks = {
    0: { label: <div className="pitch-slider-mark-label">0°</div> },
    90: { label: <div className="pitch-slider-mark-label">90°</div> },
  }

  const handleChange = (pitch: number) => {
    setViewState((currentState) => ({ ...currentState, pitch }))
  }

  return (
    <div className="pitch-slider-container">
      <Slider
        marks={marks}
        max={90}
        min={0}
        onChange={handleChange}
        style={{ pointerEvents: 'all' }}
        tooltip={{ placement: 'right' }}
        value={viewState.pitch}
        vertical
      />
      <div className="pitch-slider-label">
        <strong>Map Pitch</strong>
      </div>
    </div>
  )
}
