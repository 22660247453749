import React from 'react'

import './LabeledField.css'

interface IProps {
  children: any
  errorText?: string
  label: string
}

export const LabeledField: React.FC<IProps> = ({
  children,
  errorText = '',
  label,
}) => {
  const labelStyle = {
    color: errorText ? 'red' : 'black',
  }

  return (
    <div className="labeled-field-container">
      <p className="field-label" style={labelStyle}>
        {label}
      </p>
      {children}
      {errorText && <p className="error-text">{errorText}</p>}
    </div>
  )
}
