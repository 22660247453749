import { Tooltip } from 'antd'
import React from 'react'

import './IconButton.css'

interface IProps {
  icon: React.ReactNode
  onClick: () => void
  tooltipText?: string
}

export const IconButton: React.FC<IProps> = ({
  icon,
  onClick,
  tooltipText,
}) => {
  const content = (
    <div className="icon-button" onClick={onClick}>
      {icon}
    </div>
  )

  if (tooltipText) {
    return <Tooltip title={tooltipText}>{content}</Tooltip>
  }

  return content
}
