import React, { useMemo, useReducer, useState } from 'react'
import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc'

import { DEFAULTS, LAYER_OPTIONS } from '../../constants'
import { TActions, IState, TLayer, IViewState } from '../../types'
import { getTimes, roundToHour } from '../../utils'
import { reducer } from '../reducer'
import { storageToState, storageToViewState } from '../storage'
import { queryToState } from '../query'

dayjs.extend(UTC)

const startTime = roundToHour(dayjs().utc()) // now

const initialState: IState = {
  activeModal: 'apiKey',
  aircraftType: DEFAULTS.AIRCRAFT_TYPE,
  apiKey: undefined,
  autoRequestData: false,
  bbox: {
    e: DEFAULTS.BBOX.E,
    n: DEFAULTS.BBOX.N,
    s: DEFAULTS.BBOX.S,
    w: DEFAULTS.BBOX.W,
  },
  cocip: {},
  downloading: false,
  editMode: 'view',
  efThreshold: DEFAULTS.EF_THRESHOLD,
  flightLevels: DEFAULTS.FLIGHT_LEVELS,
  gcontrails: {},
  gcontrailsThreshold: DEFAULTS.GOOGLE_THRESHOLD,
  layers: {
    enabled: Object.keys(LAYER_OPTIONS) as TLayer[],
    options: [],
  },
  pcr: {},
  timeline: {
    currentTime: startTime,
    times: getTimes(startTime),
  },
}

export const Context = React.createContext<{
  state: IState
  dispatch: React.Dispatch<TActions>
}>({
  state: initialState,
  dispatch: () => null,
})

export const Provider = ({ children }) => {
  const storedState = useMemo(() => storageToState(initialState), [])
  const queryState = useMemo(() => queryToState(storedState), [storedState])
  const [state, dispatch] = useReducer(reducer, queryState)

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}

export const ViewstateContext = React.createContext<IViewState>(
  DEFAULTS.VIEW_STATE
)

export const SetViewstateContext = React.createContext<React.Dispatch<any>>(
  () => null
)

export const ViewstateProvider = ({ children }) => {
  const storedState = useMemo(() => storageToViewState(DEFAULTS.VIEW_STATE), [])
  const [viewState, setViewState] = useState(storedState)

  return (
    <ViewstateContext.Provider value={viewState}>
      <SetViewstateContext.Provider value={setViewState}>
        {children}
      </SetViewstateContext.Provider>
    </ViewstateContext.Provider>
  )
}
