import { TActions, TReducers, IState } from '../../types'
import * as activeModal from './activeModal'
import * as aircraftType from './aircraftType'
import * as apiKey from './apiKey'
import * as autoRequestData from './autoRequestData'
import * as bbox from './bbox'
import * as cocip from './cocip'
import * as downloading from './downloading'
import * as efThreshold from './efThreshold'
import * as flightLevels from './flightLevels'
import * as gcontrails from './gcontrails'
import * as layers from './layers'
import * as editMode from './editMode'
import * as pcr from './pcr'
import * as timeline from './timeline'

const combineReducers = (reducers: TReducers) => {
  return (state: IState, action: TActions) => {
    const newState = Object.keys(reducers).reduce(
      (previousState, reducerType) => {
        return reducers[reducerType](previousState, action)
      },
      state
    )

    return newState
  }
}

export const reducer = combineReducers({
  activeModal: activeModal.reducer,
  aircraftType: aircraftType.reducer,
  apiKey: apiKey.reducer,
  autoRequestData: autoRequestData.reducer,
  bbox: bbox.reducer,
  cocip: cocip.reducer,
  downloading: downloading.reducer,
  editMode: editMode.reducer,
  efThreshold: efThreshold.reducer,
  flightLevels: flightLevels.reducer,
  gcontrails: gcontrails.reducer,
  layers: layers.reducer,
  pcr: pcr.reducer,
  timeline: timeline.reducer,
})

export const actionCreators = {
  ...activeModal.actionCreators,
  ...aircraftType.actionCreators,
  ...apiKey.actionCreators,
  ...autoRequestData.actionCreators,
  ...bbox.actionCreators,
  ...cocip.actionCreators,
  ...downloading.actionCreators,
  ...editMode.actionCreators,
  ...efThreshold.actionCreators,
  ...flightLevels.actionCreators,
  ...gcontrails.actionCreators,
  ...layers.actionCreators,
  ...pcr.actionCreators,
  ...timeline.actionCreators,
}
