import dayjs from 'dayjs'
import { Feature, LineString, MultiPolygon, Position } from '@turf/turf'
import { LinearInterpolator } from '@deck.gl/core/typed'

export type TColor = [number, number, number] | [number, number, number, number]

export interface IPcrProperties {
  aircraft_type: string
  cocip_dt_integration: string
  cocip_max_contrail_age: string
  humidity_scaling_formula: string
  humidity_scaling_name: string
  level: number
  level_long_name: string
  level_standard_name: string
  level_units: string
  long_name: string
  met_source: string
  name: string
  polygon_iso_value: number
  pycontrails_version: string
  time: string
  units: string
}

export interface ICocipProperties {
  aircraft_type: string
  cocip_dt_integration: string
  cocip_max_contrail_age: string
  humidity_scaling_formula: string
  humidity_scaling_name: string
  level: number
  level_long_name: string
  level_standard_name: string
  level_units: string
  long_name: string
  met_source: string
  name: string
  polygon_iso_value: number
  pycontrails_version: string
  time: string
  units: string
}

export interface IGContrailsProperties {
  Originating_or_generating_Center: string
  level: number
  level_long_name: string
  level_standard_name: string
  level_units: string
  long_name: string
  name: string
  polygon_iso_value: number
  time: string
  units: string
}
export interface IFlightData {
  altitude: number[]
  latitude: number[]
  longitude: number[]
  time?: number[]
  engine_efficiency?: number[]
  aircraft_mass?: number[]
  fuel_flow?: number[]
  wingspan?: number
  aircraft_type?: string
  engine_uid?: string
  flight_id?: string
  met_source?: string
  format?: string
  mode?: string
  _skip_validate?: string
}
export interface IFlightDataPayload {
  altitude: number[]
  identifier?: string[]
  latitude: number[]
  longitude: number[]
}
export type TPcrSingleResponse = Feature<MultiPolygon, IPcrProperties>
export type TPcrResponse = TPcrSingleResponse[]

export type TCocipSingleResponse = Feature<MultiPolygon, ICocipProperties>
export type TCocipResponse = TCocipSingleResponse[]

export type TGContrailsSingleResponse = Feature<
  MultiPolygon,
  IGContrailsProperties
>
export type TGContrailsResponse = TGContrailsSingleResponse[]
export type TGenericMultiPolygon = Feature<MultiPolygon, any>[]
interface ITrajectoryProperties {
  name: string
}
export type TTrajectory = Feature<LineString, ITrajectoryProperties>

export interface IPathData {
  path: Position[]
  name: 'Intersect' | 'No Intersect'
  color: TColor
}
export type TAggregatedPathData = Record<string, IPathData[]>

export enum TActionTypes {
  ADD_LAYER_OPTION = 'ADD_LAYER_OPTION',
  SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL',
  SET_AIRCRAFT_TYPE = 'SET_AIRCRAFT_TYPE',
  SET_API_KEY = 'SET_API_KEY',
  SET_AUTO_REQUEST_DATA = 'SET_AUTO_REQUEST_DATA',
  SET_BBOX = 'SET_BBOX',
  SET_BBOX_EDIT_MODE = 'SET_BBOX_EDIT_MODE',
  SET_COCIP = 'SET_COCIP',
  SET_CURRENT_TIME = 'SET_CURRENT_TIME',
  SET_DOWNLOADING = 'SET_DOWNLOADING',
  SET_EF_THRESHOLD = 'SET_EF_THRESHOLD',
  SET_ENABLED_LAYERS = 'SET_ENABLED_LAYERS',
  SET_FLIGHT_LEVELS = 'SET_FLIGHT_LEVELS',
  SET_G_CONTRAILS = 'SET_G_CONTRAILS',
  SET_G_CONTRAILS_THRESHOLD = 'SET_G_CONTRAILS_THRESHOLD',
  SET_LAYER_OPTIONS = 'SET_LAYER_OPTIONS',
  SET_PCR = 'SET_PCR',
  SET_TIMES = 'SET_TIMES',
  SET_VIEW_STATE = 'SET_VIEW_STATE',
  UPDATE_VIEW_STATE = 'UPDATE_VIEW_STATE',
}

export interface IActiveModalAction extends Pick<IState, 'activeModal'> {
  type: TActionTypes.SET_ACTIVE_MODAL
}

export interface IAircraftTypeAction extends Pick<IState, 'aircraftType'> {
  type: TActionTypes.SET_AIRCRAFT_TYPE
}

export interface IApiKeyAction extends Pick<IState, 'apiKey'> {
  type: TActionTypes.SET_API_KEY
}

export interface IAutoRequestAction extends Pick<IState, 'autoRequestData'> {
  type: TActionTypes.SET_AUTO_REQUEST_DATA
}

export interface IFlightLevelsAction extends Pick<IState, 'flightLevels'> {
  type: TActionTypes.SET_FLIGHT_LEVELS
}

export interface ICocipAction {
  type: TActionTypes.SET_COCIP
  data?: TCocipResponse
  time: string
}

export interface IPcrAction {
  type: TActionTypes.SET_PCR
  data?: TPcrResponse
  time: string
}

export interface IGContrailsAction {
  type: TActionTypes.SET_G_CONTRAILS
  data?: TGContrailsResponse
  time: string
}

export interface ICurrentTimeAction extends Pick<ITimeline, 'currentTime'> {
  type: TActionTypes.SET_CURRENT_TIME
}

export interface IDownloadingAction extends Pick<IState, 'downloading'> {
  type: TActionTypes.SET_DOWNLOADING
}

export interface IEfThresholdAction extends Pick<IState, 'efThreshold'> {
  type: TActionTypes.SET_EF_THRESHOLD
}

export interface IEnabledLayersAction {
  type: TActionTypes.SET_ENABLED_LAYERS
  layers: TLayer[]
}

export interface ILayerOptionsAction {
  type: TActionTypes.SET_LAYER_OPTIONS
  layers: TLayer[]
}

export interface IAddLayerOptionAction {
  type: TActionTypes.ADD_LAYER_OPTION
  layer: TLayer
}

export interface IGContrailsThresholdAction
  extends Pick<IState, 'gcontrailsThreshold'> {
  type: TActionTypes.SET_G_CONTRAILS_THRESHOLD
}

export interface IBboxAction extends Pick<IState, 'bbox'> {
  type: TActionTypes.SET_BBOX
}

export interface ISetEditModeAction extends Pick<IState, 'editMode'> {
  type: TActionTypes.SET_BBOX_EDIT_MODE
}

export interface ITimesAction {
  type: TActionTypes.SET_TIMES
  newTimes: dayjs.Dayjs[]
}

export type TActions =
  | IActiveModalAction
  | IAddLayerOptionAction
  | IAircraftTypeAction
  | IApiKeyAction
  | IAutoRequestAction
  | IBboxAction
  | ICocipAction
  | ICurrentTimeAction
  | IDownloadingAction
  | IEfThresholdAction
  | IEnabledLayersAction
  | IFlightLevelsAction
  | IGContrailsAction
  | IGContrailsThresholdAction
  | ILayerOptionsAction
  | IPcrAction
  | ISetEditModeAction
  | ITimesAction
type TReducer = (state: IState, action: TActions) => IState

type TReducerKeyName = keyof Omit<IState, 'gcontrailsThreshold'>

export type TReducers = {
  [key in TReducerKeyName]: TReducer
}

export type TMode = 'edit' | 'draw' | 'view'

export type TDirection = 'e' | 'n' | 's' | 'w'

export type TBBox = Record<TDirection, number>

export interface ICoordinates {
  latitude: number
  longitude: number
}

export interface IQueryParams {
  bboxE: number
  bboxN: number
  bboxS: number
  bboxW: number
  format: 'geojson'
  interiors: boolean
  met_source: 'ERA5' | 'HRES'
  time: string
}

export interface ICocipQueryParams extends IQueryParams {
  aircraft_type: string
  threshold?: number
}

export interface IPcrQueryParams extends IQueryParams {
  flightLevels?: number[]
}

export interface IGContrailsQueryParams extends IQueryParams {
  threshold?: number
  variable?:
    | 'ERF'
    | 'contrails_co2e_kg_per_km'
    | 'contrails_probability'
    | 'expected_contrail_erf_GJ_per_km'
    | 'pred_contrails'
}

export type TModal = 'apiKey' | 'config' | 'none'
export type TLayer = 'cocip' | 'gcontrails' | 'trajectory' | 'pcr'

export interface IViewState {
  altitude?: number
  bearing?: number
  height?: number
  latitude: number
  longitude: number
  maxPitch?: number
  maxZoom?: number
  minPitch?: number
  minZoom?: number
  normalize?: boolean
  onTransitionEnd?: () => void
  pitch?: number
  transitionDuration?: number
  transitionEasing?: (t: any) => any
  transitionInterpolator?: LinearInterpolator
  transitionInterruption?: number
  width?: number
  zoom: number
}

export interface ITimeline {
  currentTime: dayjs.Dayjs
  times: dayjs.Dayjs[]
}

export interface IState {
  activeModal: TModal
  aircraftType: string
  apiKey?: string
  autoRequestData: boolean
  bbox: TBBox
  cocip: Record<string, TCocipResponse | undefined>
  downloading: boolean
  editMode: TMode
  efThreshold?: number
  flightLevels: [number, number] // [minLevel, maxLevel]
  gcontrails: Record<string, TGContrailsResponse | undefined>
  gcontrailsThreshold?: number | undefined
  layers: {
    enabled: TLayer[]
    options: TLayer[]
  }
  pcr: Record<string, TPcrResponse | undefined>
  timeline: ITimeline
}
