import { Feature, FeatureCollection, Polygon } from '@turf/turf'

import { TBBox } from '../../../types'

export const buildGeojsonLayerData = (
  bbox: TBBox
): FeatureCollection<Polygon> => {
  const { w, s, e, n } = bbox
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          coordinates: [
            [
              [w, n],
              [e, n],
              [e, s],
              [w, s],
              [w, n],
            ],
          ],
          type: 'Polygon',
        },
      },
    ],
  }
}

export const getBboxFromGeojson = (polygon: Feature<Polygon>): TBBox => {
  const coords = polygon.geometry.coordinates[0]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [topLeft, _bottomLeft, bottomRight, _topRight] = coords

  let w = topLeft[0]
  let n = topLeft[1]
  let e = bottomRight[0]
  let s = bottomRight[1]

  if (e < w) {
    const temp = w
    w = e
    e = temp
  }
  if (n < s) {
    const temp = n
    n = s
    s = temp
  }

  if (w < -180) w = -180
  if (e > 180) e = 180

  return {
    w,
    n,
    e,
    s,
  }
}
