import React, { useEffect, useMemo, useRef, useState } from 'react'
import { BitmapLayer, GeoJsonLayer, PathLayer } from '@deck.gl/layers/typed'
import { COORDINATE_SYSTEM } from '@deck.gl/core/typed'
import DeckGL from '@deck.gl/react/typed'
import { DataFilterExtension, PathStyleExtension } from '@deck.gl/extensions'
import {
  CompositeMode,
  DrawRectangleMode,
  ModifyMode,
  TranslateMode,
  ViewMode,
} from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { FeatureCollection, Polygon, Position } from '@turf/turf'
import { MapView } from '@deck.gl/core'
import { TileLayer } from '@deck.gl/geo-layers/typed'

import { actionCreators } from '../../state/reducer'
import { buildGeojsonLayerData, getBboxFromGeojson } from './utils'
import {
  COLOR_BBOX,
  COLOR_COCIP_RGB,
  DATE_FORMAT,
  G_CONTRAILS_RGB,
  COLOR_PCR_RGB,
  COLOR_EDITABLE_BBOX_RGB,
} from '../../constants'
import {
  ICocipProperties,
  IGContrailsProperties,
  IViewState,
  TAggregatedPathData,
  TMode,
} from '../../types'
import { useAppContext, useSetViewstate, useViewstate } from '../../hooks'
import { endpoints } from '../../api/endpoints'

interface IProps {
  flightPath?: TAggregatedPathData
}

interface EditProps {
  editContext: {
    position: Position
    positionIndexes: [number, number] // [layer index, corner index]
  }
  editType:
    | 'addFeature' // drawing finished
    | 'finishMovePosition' // moved corner
    | 'movePosition' // moving corner
    | 'translated' // moved
    | 'translating' // moving
    | 'updateTentativeFeature' // drawing (not explicitly used)
  updatedData: FeatureCollection<Polygon>
}

export const Map: React.FC<IProps> = (props) => {
  const { dispatch, state } = useAppContext()
  const viewState = useViewstate()
  const setViewState = useSetViewstate()

  const drawing = useRef(true)

  const [visibleBbox, setVisibleBbox] = useState(
    buildGeojsonLayerData({
      e: state.bbox.e,
      n: state.bbox.n,
      s: state.bbox.s,
      w: state.bbox.w,
    })
  )

  useEffect(() => {
    setVisibleBbox(buildGeojsonLayerData(state.bbox))
  }, [state.bbox])

  const compositeMode = useMemo(
    () => new CompositeMode([new TranslateMode(), new ModifyMode()]),
    []
  )

  const timeKey = state.timeline.currentTime.format(DATE_FORMAT)

  const vfrLayer = useMemo(() => {
    return new TileLayer({
      id: 'vfr',
      data: endpoints.skyvector(),
      tileSize: 256,
      renderSubLayers: (props) => {
        const {
          // @ts-ignore
          bbox: { west, south, east, north },
        } = props.tile

        return new BitmapLayer(props, {
          data: undefined,
          image: props.data,
          _imageCoordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
          bounds: [west, south, east, north],
        })
      },
    })
  }, [])

  const cocipLayer = useMemo(() => {
    const colorCocip = COLOR_COCIP_RGB

    return new GeoJsonLayer<any>({
      data: state.cocip[timeKey],
      extensions: [new DataFilterExtension({ filterSize: 1 })],
      filterEnabled: true,
      filterRange: [1, 1],
      getFilterValue: ({ properties }: { properties: ICocipProperties }) => {
        const valueInRange =
          state.flightLevels[0] <= properties.level &&
          state.flightLevels[1] >= properties.level

        return valueInRange ? 1 : 0
      },
      filled: true,
      opacity: 0.2,
      getElevation: 17000,
      extruded: true,
      getFillColor: colorCocip,
      getLineColor: colorCocip,
      id: 'cocip-layer',
      lineWidthMinPixels: 3,
      stroked: true,
      updateTriggers: {
        getFilterValue: [state.flightLevels],
        getElevation: [timeKey],
      },
      wireframe: true,
      visible: state.layers.enabled.includes('cocip'),
    })
  }, [state.cocip, state.flightLevels, state.layers.enabled, timeKey])

  const pcrLayer = useMemo(() => {
    const colorPcr = COLOR_PCR_RGB

    return new GeoJsonLayer<any>({
      data: state.pcr[timeKey],
      extensions: [new DataFilterExtension({ filterSize: 1 })],
      filterEnabled: true,
      filterRange: [1, 1],
      getFilterValue: ({ properties }: { properties: ICocipProperties }) => {
        const valueInRange =
          state.flightLevels[0] <= properties.level &&
          state.flightLevels[1] >= properties.level

        return valueInRange ? 1 : 0
      },
      filled: true,
      opacity: 0.2,
      getElevation: 17000,
      extruded: true,
      getFillColor: colorPcr,
      getLineColor: colorPcr,
      id: 'pcr-layer',
      lineWidthMinPixels: 3,
      stroked: true,
      updateTriggers: {
        getFilterValue: [state.flightLevels],
        getElevation: [timeKey],
      },
      wireframe: true,
      visible: state.layers.enabled.includes('pcr'),
    })
  }, [state.pcr, state.layers.enabled, state.flightLevels, timeKey])

  const gcontrailsLayer = useMemo(() => {
    return new GeoJsonLayer<any>({
      data: state.gcontrails[timeKey],
      extensions: [new DataFilterExtension({ filterSize: 1 })],
      filterEnabled: true,
      filterRange: [1, 1],
      getFilterValue: ({
        properties,
      }: {
        properties: IGContrailsProperties
      }) => {
        const valueInRange =
          state.flightLevels[0] <= properties.level &&
          state.flightLevels[1] >= properties.level

        return valueInRange ? 1 : 0
      },
      filled: true,
      opacity: 0.2,
      getElevation: 17000,
      extruded: true,
      getFillColor: G_CONTRAILS_RGB,
      getLineColor: G_CONTRAILS_RGB,
      id: 'gcontrails-layer',
      lineWidthMinPixels: 3,
      stroked: true,
      updateTriggers: {
        getFilterValue: [state.flightLevels],
      },
      wireframe: true,
      visible: state.layers.enabled.includes('gcontrails'),
    })
  }, [state.layers.enabled, state.flightLevels, state.gcontrails, timeKey])

  const editableGeoJsonLayer = useMemo(() => {
    const modes: Record<TMode, any> = {
      draw: DrawRectangleMode,
      edit: compositeMode,
      view: ViewMode,
    }
    const baseColor = COLOR_BBOX
    const colorEditableBbox = COLOR_EDITABLE_BBOX_RGB

    return new (EditableGeoJsonLayer as any)({
      // using visibleBbox instead of state.bbox allows the layer to update more responsively
      // since it doesn't have to update global state, which updates the entire app.
      // TODO: migrate from context to redux and see if that helps
      data: visibleBbox,
      extensions: [new PathStyleExtension({ dash: true })],
      id: 'editable-bbox-layer',
      mode: modes[state.editMode],
      pickable: true,
      // distinguishes which features to treat as selected
      selectedFeatureIndexes: [0],
      onEdit: ({ editContext, editType, updatedData }: EditProps) => {
        if (state.editMode === 'draw') {
          drawing.current = true
          // the drawn rectangle is maintained by the layer during drawing (editType === 'updateTentativeFeature')

          // addFeature is the editType when the box is finished
          // clicking to finish drawing will call the click handler again
          if (editType === 'addFeature') {
            // updatedData only contains the initial bbox during drawing,
            // and only adds the new bbox when drawing is finished.
            // So we don't need to keep checking for it throughout the drawing process
            const newRectangle = updatedData.features.find(
              ({ properties }) => properties?.shape === 'Rectangle'
            )

            if (!newRectangle) return

            const newBbox = getBboxFromGeojson(newRectangle)

            setVisibleBbox(buildGeojsonLayerData(newBbox))

            dispatch(actionCreators.setBbox(newBbox))
            // for some reason it takes 300-400ms for the geojson layer to finish editing, and
            // the bbox is hidden during draw mode, so we have to transition
            // to edit mode here to reveal the bbox while we wait for editing to end
            dispatch(actionCreators.setEditMode('edit'))
          }
        } else if (
          editType === 'movePosition' ||
          editType === 'finishMovePosition'
        ) {
          // move a corner of the bbox
          const [newLong, newLat] = editContext.position

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, cornerNumber] = editContext.positionIndexes
          let n = state.bbox.n
          let e = state.bbox.e
          let s = state.bbox.s
          let w = state.bbox.w
          // 0,0      0,1
          // 0,3      0,2
          if (cornerNumber === 0) {
            // top left
            w = newLong
            n = newLat
          } else if (cornerNumber === 1) {
            // top right
            e = newLong
            n = newLat
          } else if (cornerNumber === 2) {
            // bottom right
            e = newLong
            s = newLat
          } else if (cornerNumber === 3) {
            // bottom left
            w = newLong
            s = newLat
          }
          const newBbox = { n, e, s, w }
          const newGeojson = buildGeojsonLayerData(newBbox)
          setVisibleBbox(newGeojson)

          // finishMovePosition is the editType when the move is finished
          if (editType === 'finishMovePosition') {
            dispatch(actionCreators.setBbox(newBbox))
          }
        } else if (editType === 'translating' || editType === 'translated') {
          // translate the entire bbox
          const coords = updatedData.features[0].geometry.coordinates[0]
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [topLeft, _topRight, bottomRight, bottomLeft] = coords

          const w = bottomLeft[0]
          const n = topLeft[1]
          const e = bottomRight[0]
          const s = bottomRight[1]
          const newBbox = { w, n, e, s }

          setVisibleBbox(buildGeojsonLayerData(newBbox))

          // translated is the editType when the translation is finished
          if (editType === 'translated') {
            dispatch(actionCreators.setBbox(newBbox))
          }
        }
      },
      getEditHandlePointColor: [0, 0, 0, 0],
      getEditHandlePointOutlineColor: ({ properties }) => {
        if (properties.editHandleType === 'intermediate') {
          return [...baseColor, 0]
        }
        return [...baseColor, 255]
      },
      getDashArray: () => {
        // The dash array to draw each path with:
        // [dashSize, gapSize] relative to the width of the path.
        return state.editMode === 'view' ? [0, 0] : [5, 5]
      },
      getFillColor: () => {
        if (state.editMode === 'view') {
          return [...baseColor, 50]
        } else if (state.editMode === 'draw') {
          return [...baseColor, 0]
        } else {
          return [...colorEditableBbox, 25]
        }
      },
      getLineColor: () => {
        if (state.editMode === 'view') {
          return [...baseColor, 255]
        } else if (state.editMode === 'draw') {
          return [...baseColor, 0]
        } else {
          return [...colorEditableBbox, 255]
        }
      },
      getTentativeLineColor: [...colorEditableBbox, 255],
      getTentativeFillColor: [...colorEditableBbox, 25],
      getTentativeLineWidth: 1,
      getLineWidth: 2,
    })
  }, [
    compositeMode,
    dispatch,
    state.bbox.e,
    state.bbox.n,
    state.bbox.s,
    state.bbox.w,
    state.editMode,
    visibleBbox,
  ])

  const flightPathLayer = useMemo(() => {
    if (!props.flightPath) return

    return new PathLayer({
      id: 'path-layer',
      data: props.flightPath[timeKey],
      pickable: true,
      widthScale: 20,
      widthMinPixels: 2,
      getPath: (d) => d.path,
      getColor: (d) => d.color,
      getWidth: 5,
      visible: state.layers.enabled.includes('trajectory'),
    })
  }, [props.flightPath, state.layers.enabled, timeKey])

  /*
    translating and dragging corner handles do not register a click
    when in edit/draw mode, the onEdit callback of the editablegeojsonlayer is called first,
    then handleClick is called. the final click to finish drawing also calls handleClick.
  */
  const handleClick = ({ layer }) => {
    if (state.editMode === 'edit') {
      if (drawing.current) {
        drawing.current = false
      } else {
        dispatch(actionCreators.setEditMode('view'))
      }
    } else {
      if (layer && layer.id === 'editable-bbox-layer') {
        dispatch(actionCreators.setEditMode('edit'))
      }
    }
  }

  return (
    <DeckGL
      controller={true}
      onClick={handleClick}
      getCursor={editableGeoJsonLayer.getCursor.bind(editableGeoJsonLayer)}
      // Type 'MapView' is not assignable to type 'View<TransitionProps, {}> |
      // View<TransitionProps, {}>[] | null | undefined'.
      // @ts-ignore
      views={new MapView({ repeat: true })}
      layers={[
        vfrLayer,
        flightPathLayer,
        editableGeoJsonLayer,
        cocipLayer,
        pcrLayer,
        gcontrailsLayer,
      ]}
      onViewStateChange={({ viewState: newViewState }) => {
        setViewState(newViewState as IViewState)
      }}
      viewState={viewState}
    />
  )
}
