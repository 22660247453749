import { Position } from '@turf/turf'

import { IFlightDataPayload } from '../../../types'

export const collectCoordinates = (
  data: IFlightDataPayload
): Position[] | undefined => {
  const { altitude, latitude, longitude } = data

  if (!altitude || !latitude || !longitude) return
  if (
    altitude.length !== latitude.length ||
    altitude.length !== longitude.length
  ) {
    return
  }

  const coordinates: Position[] = []

  altitude.forEach((_, index) => {
    coordinates.push([longitude[index], latitude[index], altitude[index]])
  })

  return coordinates
}
