import {
  IAutoRequestAction,
  IState,
  TActions,
  TActionTypes,
} from '../../../types'

export const actionCreators = {
  setAutoRequestData: (autoRequestData: boolean): IAutoRequestAction => ({
    type: TActionTypes.SET_AUTO_REQUEST_DATA,
    autoRequestData,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_AUTO_REQUEST_DATA: {
      return {
        ...state,
        autoRequestData: action.autoRequestData,
      }
    }
    default:
      return state
  }
}
