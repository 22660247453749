import {
  IPcrAction,
  IState,
  TActionTypes,
  TActions,
  TPcrResponse,
  TPcrSingleResponse,
} from '../../../types'
import { newData } from '../../../utils'

export const actionCreators = {
  setPcr: (time: string, data?: TPcrResponse): IPcrAction => ({
    type: TActionTypes.SET_PCR,
    data,
    time,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_PCR: {
      return {
        ...state,
        pcr: {
          ...state.pcr,
          [action.time]: newData<TPcrSingleResponse>(
            action.data,
            state.pcr[action.time]
          ),
        },
      }
    }
    default:
      return state
  }
}
