import React from 'react'
import { DownloadOutlined } from '@ant-design/icons'

import './DropTarget.css'

interface IProps {
  onDragLeave: () => void
  visible: boolean
}

export const DropTarget: React.FC<IProps> = ({ visible, onDragLeave }) => {
  if (!visible) return null

  return (
    <div onDragLeave={onDragLeave} className="drop-target">
      <div className="drop-target-icon-container">
        <DownloadOutlined style={{ fontSize: '34px' }} />
      </div>
      <div className="drop-target-content-container">
        <div className="drop-target-content-primary">
          drop file here to visualize trajectory
        </div>
        <div className="drop-target-content-secondary">
          drop file here to visualize trajectory
        </div>
      </div>
    </div>
  )
}
