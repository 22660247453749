import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { ApiKeyModal } from './components/ApiKeyModal'
import { LocalStorageManagement } from './components/LocalStorageManagement'
import { MapPage } from './pages/map'
import { Provider, ViewstateProvider } from './state/store'
import { QueryStringManagement } from './components/QueryStringManagement'

function App() {
  return (
    <Provider>
      <ViewstateProvider>
        <ApiKeyModal />
        <LocalStorageManagement />
        <QueryStringManagement />

        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<MapPage />} />

              <Route path="*" element={<MapPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ViewstateProvider>
    </Provider>
  )
}

export default App
