import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Drawer,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import dayjs from 'dayjs'
import type { RadioChangeEvent } from 'antd'
import UTC from 'dayjs/plugin/utc'

import { actionCreators } from '../../state/reducer'
import { API } from '../../api'
import {
  AIRCRAFT_TYPES,
  DATE_FORMAT,
  DEFAULTS,
  FLIGHT_LEVELS,
  LAYER_OPTIONS,
} from '../../constants'
import { findBoundingBoxCenter, getTimes, roundToHour } from '../../utils'
import { LabeledField } from '../LabeledField'
import {
  IQueryParams,
  ICocipQueryParams,
  IGContrailsQueryParams,
  TDirection,
  IPcrQueryParams,
  TPcrResponse,
} from '../../types'
import { useAppContext, useSetViewstate } from '../../hooks'
import './ConfigDrawer.css'

dayjs.extend(UTC)

const DRAWER_PADDING = 15

type TRequestType = 'cocip' | 'pcr'
const COCIP = 'cocip'
const PCR = 'pcr'

export const ConfigDrawer: React.FC = () => {
  const { dispatch, state } = useAppContext()
  const setViewState = useSetViewstate()

  const [loading, setLoading] = useState(false)
  const [apiKeyError, setApiKeyError] = useState(false)
  const [requestType, setRequestType] = useState<TRequestType>(COCIP)
  const [componentAircraftType, setComponentAircraftType] = useState(
    state.aircraftType
  )
  const [componentApiKey, setComponentApiKey] = useState<string | undefined>()
  const [componentTime, setComponentTime] = useState<dayjs.Dayjs | null>(
    state.timeline.currentTime
  )
  const [componentEfThreshold, setComponentEfThreshold] = useState<
    number | null
  >(state.efThreshold ?? null)
  const [componentGcontrailsThreshold, setComponentGcontrailsThreshold] =
    useState<number | null>(state.gcontrailsThreshold ?? null)

  useEffect(() => {
    if (componentApiKey === undefined) {
      setComponentApiKey(state.apiKey)
    }
  }, [componentApiKey, state.apiKey])

  const handleApiKeyChange = ({ target }: React.SyntheticEvent) => {
    if (apiKeyError) {
      setApiKeyError(false)
    }
    setComponentApiKey((target as HTMLInputElement).value)
  }

  const submitDisabled =
    !componentApiKey ||
    !componentTime ||
    state.bbox.e === null ||
    state.bbox.n === null ||
    state.bbox.s === null ||
    state.bbox.w === null

  const handleSubmit = useCallback(() => {
    if (submitDisabled) return

    setLoading(true)

    const newTimes = getTimes(componentTime)
    dispatch(actionCreators.setTimeline(newTimes))

    dispatch(actionCreators.setAircraftType(componentAircraftType))

    if (componentEfThreshold) {
      dispatch(actionCreators.setEfThreshold(componentEfThreshold))
    }

    if (componentGcontrailsThreshold) {
      dispatch(
        actionCreators.setGContrailsThreshold(componentGcontrailsThreshold)
      )
    }

    dispatch(actionCreators.setBbox(state.bbox))

    dispatch(actionCreators.setDownloading(true))

    const baseParams: Omit<IQueryParams, 'time'> = {
      bboxE: state.bbox.e,
      bboxN: state.bbox.n,
      bboxS: state.bbox.s,
      bboxW: state.bbox.w,
      format: DEFAULTS.FORMAT,
      interiors: DEFAULTS.INTERIORS,
      met_source: DEFAULTS.MET_SOURCE,
    }

    const cocipQueryParams: Omit<ICocipQueryParams, 'time'> = {
      ...baseParams,
      aircraft_type: DEFAULTS.AIRCRAFT_TYPE,
      ...(componentAircraftType
        ? { aircraft_type: componentAircraftType }
        : {}),
      ...(componentEfThreshold ? { threshold: componentEfThreshold } : {}),
    }
    const pcrQueryParams: Omit<IPcrQueryParams, 'time'> = {
      ...baseParams,
    }

    const gcontrailsQueryParams: Omit<IGContrailsQueryParams, 'time'> = {
      ...baseParams,
      threshold: componentGcontrailsThreshold ?? state.gcontrailsThreshold,
      variable: 'contrails_probability',
    }

    const requests = newTimes.flatMap((dayJsTime) => {
      const time = dayJsTime.format(DATE_FORMAT)
      const cocipParamsWithTime = { ...cocipQueryParams, time }
      const pcrParamsWithTime = { ...pcrQueryParams, time }
      const cocipRequest = () =>
        API.cocip
          .load(cocipParamsWithTime, componentApiKey)
          .then((data) => {
            dispatch(actionCreators.addLayerOption('cocip'))
            dispatch(actionCreators.setCocip(time, data))

            if (state.apiKey !== componentApiKey) {
              dispatch(actionCreators.setApiKey(componentApiKey))
            }
            if (state.activeModal === 'config') {
              dispatch(actionCreators.setActiveModal('none'))
            }
          })
          .catch((e) => {
            dispatch(actionCreators.setCocip(time, undefined))

            const message = e.response.data.detail.msg

            if (message.includes('Invalid API Key')) {
              dispatch(actionCreators.setApiKey(undefined))
              setApiKeyError(true)
            }
          })
      const pcrRequest = () => {
        const allLevels = [
          FLIGHT_LEVELS.slice(0, 5),
          FLIGHT_LEVELS.slice(5, 10),
          FLIGHT_LEVELS.slice(10, 15),
          FLIGHT_LEVELS.slice(15),
        ]

        const levelRequests = allLevels.map((levels) => {
          const params: IPcrQueryParams = {
            ...pcrParamsWithTime,
            flightLevels: levels,
          }
          return API.pcr
            .load(params, componentApiKey)
            .then((data) => {
              if (state.apiKey !== componentApiKey) {
                dispatch(actionCreators.setApiKey(componentApiKey))
              }
              if (state.activeModal === 'config') {
                dispatch(actionCreators.setActiveModal('none'))
              }
              return data
            })
            .catch((e) => {
              const message = e.response?.data?.detail?.msg

              if (message && message.includes('Invalid API Key')) {
                dispatch(actionCreators.setApiKey(undefined))
                setApiKeyError(true)
              }

              return undefined
            })
        })

        return Promise.all(levelRequests)
          .then((levelResponses) => {
            const features: TPcrResponse = []

            levelResponses.forEach((response) => {
              if (response) {
                response.forEach((feature) => {
                  features.push(feature)
                })
              }
            })

            return features
          })
          .then((data) => {
            dispatch(actionCreators.addLayerOption('pcr'))
            dispatch(actionCreators.setPcr(time, data))

            if (data.length === 0) {
              dispatch(actionCreators.setPcr(time, undefined))
            }
          })
      }

      const requestPromises = {
        cocip: [cocipRequest],
        pcr: [pcrRequest],
      }

      // only make gcontrails request if threshold is defined
      // using query parameter or saved state
      if (state.gcontrailsThreshold) {
        const gcontrailsParams = {
          ...gcontrailsQueryParams,
          time,
        }

        const gcontrailsRequest = () =>
          API.gcontrails
            .load(gcontrailsParams, componentApiKey)
            .then((data) => {
              dispatch(actionCreators.addLayerOption('gcontrails'))
              dispatch(actionCreators.setGContrails(time, data))
            })
            .catch(() => {
              dispatch(actionCreators.addLayerOption('gcontrails'))
              dispatch(actionCreators.setGContrails(time, undefined))
            })

        requestPromises[requestType].push(gcontrailsRequest)
      }

      return requestPromises[requestType]
    })

    const promises = requests.map((request) => request())

    Promise.allSettled(promises).finally(() => {
      setLoading(false)
      dispatch(actionCreators.setDownloading(false))
    })

    setViewState((currentState) => ({
      ...currentState,
      ...findBoundingBoxCenter(state.bbox),
    }))

    dispatch(actionCreators.setEditMode('view'))
  }, [
    componentAircraftType,
    componentApiKey,
    componentEfThreshold,
    componentGcontrailsThreshold,
    componentTime,
    dispatch,
    state.bbox,
    requestType,
    state.activeModal,
    state.apiKey,
    state.gcontrailsThreshold,
    setViewState,
    submitDisabled,
  ])

  useEffect(() => {
    if (state.autoRequestData && componentApiKey) {
      handleSubmit()
      dispatch(actionCreators.setAutoRequestData(false))
    }
  }, [dispatch, componentApiKey, handleSubmit, state.autoRequestData])

  const handleCancel = () => {
    dispatch(actionCreators.setActiveModal('none'))
  }

  const updateBbox = (v: number | null, direction: TDirection) => {
    const newBbox = {
      ...state.bbox,
      ...(v !== null ? { [direction]: v } : {}),
    }
    dispatch(actionCreators.setBbox(newBbox))
  }

  const handleTimeChange = (v: dayjs.Dayjs | null) => {
    if (!v) {
      setComponentTime(v)
    } else {
      const utc = v.utc()
      const roundedTime = roundToHour(utc)

      setComponentTime(roundedTime)
    }
  }

  const roundCoord = (coord: number | null) => {
    if (coord === null) return null

    return Math.floor(coord * 10) / 10
  }

  const handleToggleChange = (e: RadioChangeEvent) => {
    setRequestType(e.target.value)
  }

  return (
    <Drawer
      afterOpenChange={(open: boolean) => {
        if (open) {
          dispatch(actionCreators.setEditMode('edit'))
        } else {
          dispatch(actionCreators.setEditMode('view'))
        }
      }}
      bodyStyle={{ padding: DRAWER_PADDING }}
      footer={
        <Space>
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            disabled={submitDisabled}
            key="submit"
            loading={loading}
            onClick={handleSubmit}
            type="primary"
          >
            Request Data
          </Button>
        </Space>
      }
      footerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
      headerStyle={{ padding: DRAWER_PADDING }}
      mask={false}
      onClose={handleCancel}
      open={state.activeModal === 'config'}
      title="Configure Request"
      width={300}
    >
      <Row>
        <Col span={24}>
          <LabeledField label="Start Time (z)">
            <DatePicker
              format="YYYY-MM-DD HH:00"
              onChange={handleTimeChange}
              showNow={true}
              showTime={{ format: 'HH' }}
              style={{ width: '100%' }}
              value={componentTime}
            />
          </LabeledField>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <LabeledField
            label="API Key"
            errorText={apiKeyError ? 'Invalid API Key' : ''}
          >
            <Input
              onChange={handleApiKeyChange}
              style={{ width: '100%' }}
              value={componentApiKey}
              status={apiKeyError ? 'error' : undefined}
            />
          </LabeledField>
        </Col>
      </Row>

      <LabeledField label="Bounding Box">
        <Row style={{ marginBottom: 10 }}>
          <Col span={6} offset={9}>
            <InputNumber
              id="bbox-n"
              max={90}
              min={-90}
              onChange={(v) => updateBbox(v, 'n')}
              step="1"
              style={{ width: '100%' }}
              value={roundCoord(state.bbox.n)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <div className="bbox-middle-row">
            <InputNumber
              id="bbox-w"
              max={180}
              min={-180}
              onChange={(v) => updateBbox(v, 'w')}
              step={1}
              style={{ width: '66px' }}
              value={roundCoord(state.bbox.w)}
            />
            <Button
              onClick={() => {
                if (state.editMode === 'draw') {
                  dispatch(actionCreators.setEditMode('view'))
                } else {
                  dispatch(actionCreators.setEditMode('draw'))
                }
              }}
              style={{ width: '100px' }}
              type={state.editMode === 'draw' ? 'primary' : 'default'}
            >
              Draw
            </Button>
            <InputNumber
              id="bbox-e"
              max={180}
              min={-180}
              onChange={(v) => updateBbox(v, 'e')}
              step={1}
              style={{ width: '66px' }}
              value={roundCoord(state.bbox.e)}
            />
          </div>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col span={6} offset={9}>
            <InputNumber
              id="bbox-s"
              max={90}
              min={-90}
              onChange={(v) => updateBbox(v, 's')}
              step={1}
              style={{ width: '100%' }}
              value={roundCoord(state.bbox.s)}
            />
          </Col>
        </Row>
      </LabeledField>
      <Row>
        <LabeledField label="Contrail regions">
          <Radio.Group
            buttonStyle="solid"
            defaultValue={COCIP}
            onChange={handleToggleChange}
            value={requestType}
          >
            <Radio.Button
              style={{ fontSize: '13px', width: '50%' }}
              value={COCIP}
            >
              {LAYER_OPTIONS[COCIP]}
            </Radio.Button>
            <Radio.Button
              style={{ fontSize: '13px', width: '50%' }}
              value={PCR}
            >
              {LAYER_OPTIONS[PCR]}
            </Radio.Button>
          </Radio.Group>
        </LabeledField>
      </Row>

      {requestType === COCIP ? (
        <Collapse ghost>
          <Collapse.Panel header="Advanced" key="1">
            <Row>
              <Col span={24}>
                <LabeledField label="Aircraft Type">
                  <Select
                    style={{ width: '100%' }}
                    onChange={(v) => setComponentAircraftType(v)}
                    options={AIRCRAFT_TYPES.map((type) => ({
                      value: type,
                      label: type,
                    }))}
                    value={componentAircraftType}
                  />
                </LabeledField>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <LabeledField label="EF Threshold">
                  <InputNumber
                    max={1e10}
                    min={0}
                    onChange={(v) => setComponentEfThreshold(v)}
                    step={1e7}
                    style={{ width: '100%' }}
                    value={componentEfThreshold}
                  />
                </LabeledField>
              </Col>

              {state.gcontrailsThreshold && (
                <Col span={24}>
                  <LabeledField label="GContrails Threshold">
                    <InputNumber
                      min={0}
                      onChange={(v) => setComponentGcontrailsThreshold(v)}
                      step={1e7}
                      style={{ width: '100%' }}
                      value={componentGcontrailsThreshold}
                    />
                  </LabeledField>
                </Col>
              )}
            </Row>
          </Collapse.Panel>
        </Collapse>
      ) : null}
    </Drawer>
  )
}
