import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc'

import { IState, IViewState } from '../../types'
import { LOCAL_STORAGE } from '../../constants'
import { findBoundingBoxCenter, getTimes } from '../../utils'

dayjs.extend(UTC)

export const storageToState = (initialState: IState): IState => {
  const apiKey = localStorage.getItem(LOCAL_STORAGE.API_KEY)
  const bboxString = localStorage.getItem(LOCAL_STORAGE.BBOX)
  const flightLevelsString = localStorage.getItem(LOCAL_STORAGE.LEVELS)
  const startDateString = localStorage.getItem(LOCAL_STORAGE.START_DATE)

  const storageState: Partial<IState> = {}

  if (apiKey) {
    storageState.apiKey = apiKey
    storageState.activeModal = 'config'
  }

  if (bboxString) {
    const bbox = JSON.parse(bboxString)
    const { n, s, e, w } = bbox

    storageState.bbox = initialState.bbox

    storageState.bbox.w = Number(w)
    storageState.bbox.s = Number(s)
    storageState.bbox.e = Number(e)
    storageState.bbox.n = Number(n)
  }

  if (startDateString) {
    const startTime = dayjs(startDateString).utc()

    storageState.timeline = {
      currentTime: startTime,
      times: getTimes(startTime),
    }
  }

  if (flightLevelsString) {
    const levels = JSON.parse(flightLevelsString)

    storageState.flightLevels = levels
  }

  const generatedState: IState = {
    ...initialState,
    ...storageState,
  }

  return generatedState
}

export const storageToViewState = (initialState: IViewState): IViewState => {
  const bboxString = localStorage.getItem(LOCAL_STORAGE.BBOX)

  if (bboxString) {
    const bbox = JSON.parse(bboxString)

    return {
      ...initialState,
      ...findBoundingBoxCenter(bbox),
    }
  }

  return initialState
}
