import { Position } from '@turf/turf'
import JSZip from 'jszip'
import { TTrajectory } from '../../../types'

export const buildGeojson = (
  file: File,
  callback: (geojson: TTrajectory) => void
) => {
  if (file.type === 'application/zip') {
    const zipReader = new JSZip()

    zipReader.loadAsync(file).then((zip) => {
      const datFiles = zip.file(/^.*\.(dat)$/)

      if (datFiles.length === 0) return

      datFiles[0]
        .async('blob')
        .then(JSZip.loadAsync)
        .then((innerZip) => innerZip.file('OFP.xml')?.async('text'))
        .then((ofp) => {
          if (ofp) {
            const geojson = parseXml(ofp)
            callback(geojson)
          }
        })
    })

    return
  }

  const reader = new FileReader()
  let geojson: TTrajectory | undefined = undefined

  reader.readAsText(file)
  reader.onload = (event) => {
    const data = event.target?.result

    if (data && typeof data === 'string') {
      if (file.type === 'text/xml') {
        geojson = parseXml(data)
      } else if (file.type === 'application/json') {
        geojson = parseJson(data)
      }

      if (geojson) {
        callback(geojson)
      }
    }
  }
}

const getFirstElement = (element: Element, name: string): Element => {
  return element.getElementsByTagName(name)[0]
}

const getAttribute = (nodeMap: NamedNodeMap, itemName: string) => {
  return nodeMap.getNamedItem(itemName)?.value
}

const getCoordinates = ({ attributes }: Element): [number, number] => {
  const latitudeAttribute = getAttribute(attributes, 'latitude')
  const latitudeSeconds = Number(latitudeAttribute)

  const longitudeAttribute = getAttribute(attributes, 'longitude')
  const longitudeSeconds = Number(longitudeAttribute)

  return [longitudeSeconds / 3600, latitudeSeconds / 3600]
}

const getAltitude = (collection: Element): number => {
  const estimatedAltitude = getFirstElement(collection, 'EstimatedAltitude')
  const altitudeValue = getFirstElement(estimatedAltitude, 'Value')

  const altitude = Number(altitudeValue.textContent)

  return altitude * 100
}

export const parseXml = (data: string): TTrajectory => {
  const parser = new DOMParser()

  const xmlDoc = parser.parseFromString(data, 'text/xml')

  // assume that the first <Waypoints> tag is the actual proposed routes (rather than Alternate Routes)
  const primaryWaypoints = xmlDoc.getElementsByTagName('Waypoints')[0]
  const waypoints = primaryWaypoints.getElementsByTagName('Waypoint')
  const coordinates: Position[] = []

  Array.from(waypoints).forEach((waypoint, waypointIdx) => {
    const coordinatesElement = getFirstElement(waypoint, 'Coordinates')
    const altitudeElement = getFirstElement(waypoint, 'Altitude')

    if ((!altitudeElement && waypointIdx > 0) || !coordinatesElement) return

    const longitudeLatitude = getCoordinates(coordinatesElement)
    const altitude =
      !altitudeElement && waypointIdx === 0 ? 0 : getAltitude(altitudeElement)
    const position: Position = [...longitudeLatitude, altitude]

    coordinates.push(position)
  })

  return {
    type: 'Feature',
    properties: { name: 'Converted from XML' },
    geometry: {
      type: 'LineString',
      coordinates,
    },
  }
}

export const parseJson = (data: string): TTrajectory => {
  return JSON.parse(data)
}
