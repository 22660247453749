import React, { useState } from 'react'
import { Button, Input, Modal } from 'antd'

import { actionCreators } from '../../state/reducer'
import { useAppContext } from '../../hooks'
import './ApiKeyModal.css'

export const ApiKeyModal: React.FC = () => {
  const { state, dispatch } = useAppContext()

  const [componentApiKey, setComponentApiKey] = useState<string>()
  const [error, setError] = useState<string>()

  const handleSubmit = () => {
    if (!componentApiKey) return

    dispatch(actionCreators.setApiKey(componentApiKey))
    dispatch(actionCreators.setActiveModal('config'))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setComponentApiKey(e.target.value)
  }

  return (
    <Modal
      title="Welcome to the Contrails Navigator"
      open={state.activeModal === 'apiKey'}
      onOk={handleSubmit}
      closable={false}
      footer={[
        <Button
          disabled={!componentApiKey}
          key="submit"
          onClick={handleSubmit}
          type="primary"
        >
          Submit
        </Button>,
      ]}
    >
      <p>
        <em>
          This dashboard provides a visual interface to contrail forecast data
          available through the{' '}
          <a
            href="https://api.contrails.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            Contrails API
          </a>
        </em>
      </p>

      <br />

      <p>Enter your API Key below to get started.</p>

      <Input
        {...(error ? { status: 'error' } : {})}
        id="api-key-input"
        onChange={handleChange}
        onPressEnter={handleSubmit}
        placeholder="API key"
        value={componentApiKey}
      />
      {error && <div className="error-label">{error}</div>}

      <p className="contact">
        Contact{' '}
        <a href="mailto:api&#37;&#52;&#48;contrails&#46;org">
          api&#64;contrails&#46;org
        </a>{' '}
        to request a user account.
      </p>
    </Modal>
  )
}
