import { AIRCRAFT_TYPES } from '../../../constants'
import {
  IAircraftTypeAction,
  IState,
  TActions,
  TActionTypes,
} from '../../../types'

export const actionCreators = {
  setAircraftType: (aircraftType: string): IAircraftTypeAction => ({
    type: TActionTypes.SET_AIRCRAFT_TYPE,
    aircraftType,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_AIRCRAFT_TYPE: {
      if (AIRCRAFT_TYPES.includes(action.aircraftType)) {
        return {
          ...state,
          aircraftType: action.aircraftType,
        }
      }
      return state
    }
    default:
      return state
  }
}
