import {
  IActiveModalAction,
  IState,
  TActionTypes,
  TActions,
  TModal,
} from '../../../types'

export const actionCreators = {
  setActiveModal: (activeModal: TModal): IActiveModalAction => ({
    type: TActionTypes.SET_ACTIVE_MODAL,
    activeModal,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_ACTIVE_MODAL: {
      return {
        ...state,
        activeModal: action.activeModal,
      }
    }
    default:
      return state
  }
}
