import { ConfigProvider } from 'antd'
import React from 'react'
import { render } from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {
  BODY_FONT_FAMILY,
  COLOR_ORANGE,
  COLOR_NAVY,
  COLOR_GRAYLITE,
  COLOR_RED,
} from './constants'

const root = document.getElementById('root')

// Default AntD theme
// See https://ant.design/docs/react/customize-theme#switch-themes-dynamically
// Overridden for alternate themes in MapPage Component
const DEFAULT_THEME = {
  token: {
    fontFamily: BODY_FONT_FAMILY,
    colorPrimary: COLOR_NAVY,
    colorWarning: COLOR_ORANGE,
    colorError: COLOR_RED,
  },
  components: {
    Slider: {
      railBg: COLOR_GRAYLITE,
      railHoverBg: COLOR_GRAYLITE,
      // railSize: 2,  // affects the trackSize too
    },
  },
}

render(
  // Configure Ant Design defaults
  <ConfigProvider theme={DEFAULT_THEME}>
    <App />
  </ConfigProvider>,
  root
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
