import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

import { useAppContext, useAppUtilities } from '../../hooks'
import './LoadingSpinner.css'

export const LoadingSpinner: React.FC = () => {
  const { state } = useAppContext()
  const { slicesDownloaded, slicesTotal } = useAppUtilities()

  if (!state.downloading) return null

  return (
    <div className="spinner-container">
      <LoadingOutlined style={{ fontSize: 24 }} spin />
      <h4 className="spinner-text">
        {`Loading ${slicesDownloaded} of ${slicesTotal}...`}
      </h4>
    </div>
  )
}
