import {
  IDownloadingAction,
  IState,
  TActions,
  TActionTypes,
} from '../../../types'

export const actionCreators = {
  setDownloading: (downloading: boolean): IDownloadingAction => ({
    type: TActionTypes.SET_DOWNLOADING,
    downloading,
  }),
}

export const reducer = (state: IState, action: TActions): IState => {
  switch (action.type) {
    case TActionTypes.SET_DOWNLOADING: {
      return {
        ...state,
        downloading: action.downloading,
      }
    }
    default:
      return state
  }
}
