import axios from 'axios'
import { Position } from '@turf/turf'

import { collectCoordinates } from './utils'
import { endpoints } from '../endpoints'
import { exaggerateLine } from '../../utils'
import { IFlightDataPayload } from '../../types'
import { REQUEST_CANCELED, REQUEST_TIMEOUT } from '../../constants'

interface IFlightDataResponseSuccess {
  coordinates: Position[]
  flightData: IFlightDataPayload
  error?: never
}

interface IFlightDataResponseFailure {
  coordinates?: never
  flightData?: never
  error: string
}

type TFlightDataResponse =
  | IFlightDataResponseSuccess
  | IFlightDataResponseFailure

export interface IFlightPlanApi {
  load: (
    waypoints: string,
    apiKey: string,
    signal: AbortSignal
  ) => Promise<TFlightDataResponse>
}

const GENERIC_ERROR = 'Waypoints could not be parsed'

export const flightPlan: IFlightPlanApi = {
  load: async (waypoints, apiKey, signal) => {
    try {
      const response = await axios({
        method: 'get',
        timeout: REQUEST_TIMEOUT,
        url: endpoints.flightPlan(waypoints),
        headers: {
          'x-api-key': apiKey,
        },
        signal,
      })
      const flightData: IFlightDataPayload = response.data
      const coordinates = collectCoordinates(flightData)

      if (!coordinates) {
        return { error: GENERIC_ERROR }
      }

      exaggerateLine(coordinates)

      return { flightData, coordinates }
    } catch (e: any) {
      if (e.message === 'canceled') {
        return { error: REQUEST_CANCELED }
      }

      if (e.response?.data?.detail?.msg) {
        return { error: e.response.data.detail.msg }
      }

      if (e.message) {
        return { error: e.message }
      }

      return { error: GENERIC_ERROR }
    }
  },
}
