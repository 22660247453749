import React from 'react'

import { actionCreators } from '../../state/reducer'
import { TLayer } from '../../types'
import { LAYER_OPTIONS } from '../../constants'
import { useAppContext } from '../../hooks'
import './LayerToggle.css'

export const LayerToggle: React.FC = () => {
  const { dispatch, state } = useAppContext()

  const handleChange = (layer: TLayer) => {
    const checked = state.layers.enabled.includes(layer)
    let newLayers: TLayer[] = []

    if (checked) {
      newLayers = state.layers.enabled.filter((o) => o !== layer)
    } else {
      newLayers = [...state.layers.enabled, layer]
    }

    dispatch(actionCreators.setEnabledLayers(newLayers))
  }

  if (state.layers.options.length === 0) return null

  const inputLabelNames = ['input-checkbox']
  return (
    <div className="layer-toggle-container">
      <p className="layer-toggle-title">Layers</p>
      {state.layers.options.map((layer) => (
        <div className="layer-option-container" key={layer}>
          <label className="layer-option">
            <input
              className={inputLabelNames.join(' ')}
              onChange={() => handleChange(layer)}
              type="checkbox"
              checked={state.layers.enabled.includes(layer)}
            />
            {LAYER_OPTIONS[layer]}
          </label>
        </div>
      ))}
    </div>
  )
}
