import React, { useEffect, useMemo } from 'react'
import { Slider } from 'antd'
import type { SliderMarks } from 'antd/es/slider'

import { actionCreators } from '../../state/reducer'
import { FLIGHT_LEVELS, FLIGHT_LEVEL_STEP_SIZE } from '../../constants'
import { useAppContext } from '../../hooks'
import './FlightLevelLine.css'

export const FlightLevelLine: React.FC = () => {
  const { dispatch, state } = useAppContext()

  useEffect(() => {
    const tracks = document.getElementsByClassName('ant-slider-track-1')

    if (tracks.length > 0) {
      const track = tracks[0]
      const dragTarget = document.createElement('div')
      dragTarget.className = 'drag-target'

      track.appendChild(dragTarget)
    }
  }, [])

  const marks = useMemo(() => {
    const sliderMarks: SliderMarks = {}

    FLIGHT_LEVELS.forEach((level) => {
      const active =
        level >= state.flightLevels[0] && level <= state.flightLevels[1]

      // see Timeline.css for slider-label.active classes
      const outerLabelClasses = [
        'slider-label',
        'vertical-slider',
        `${active ? 'active' : ''}`,
      ]

      sliderMarks[level] = {
        label: <div className={outerLabelClasses.join(' ')}>{level}</div>,
      }
    })

    return sliderMarks
  }, [state.flightLevels])

  const handleChange = (value: [number, number]) => {
    dispatch(actionCreators.setFlightLevels(value))
  }

  const formatter = (value?: number) => {
    if (!value) return 'Data unavailable'

    return value
  }

  return (
    <div className="flightlevel-line-container">
      <Slider
        dots={true}
        included={true}
        marks={marks}
        max={FLIGHT_LEVELS[FLIGHT_LEVELS.length - 1]}
        min={FLIGHT_LEVELS[0]}
        onChange={handleChange}
        range={{ draggableTrack: true }}
        step={FLIGHT_LEVEL_STEP_SIZE}
        tooltip={{ placement: 'left', formatter }}
        value={[state.flightLevels[0], state.flightLevels[1]]}
        vertical
      />
      <div className="flightlevel-line-label">
        <strong>Flight Level (ft x 100)</strong>
      </div>
    </div>
  )
}
