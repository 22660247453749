import { TColor, TLayer } from './types'

export const MAPBOX_STYLE = 'orca-contrails/clij4prsp00qd01p7hr77d9tn'
export const MAPBOX_TOKEN =
  'pk.eyJ1Ijoib3JjYS1jb250cmFpbHMiLCJhIjoiY2tyejltemVnMGFtejJ5cnd6a2tvamppMCJ9.KOioQz7ZBkl0SqJGkuwA2A'
export const DATA_URI = 'https://api.contrails.org/v0'

export const SKYVECTOR_API_KEY = 'JbnTiFtP5N7Dhs3pajr0lQ'
export const SKYVECTOR_URI = `https://t.skyvector.com/${SKYVECTOR_API_KEY}`

export const LOCAL_STORAGE = {
  AIRCRAFT_TYPE: 'contrailsAircraftType',
  API_KEY: 'contrailsApiKey',
  BBOX: 'contrailsBbox',
  EF_THRESHOLD: 'contrailsEfThreshold',
  G_CONTRAILS_THRESHOLD: 'contrailsGcontrailsThreshold',
  LEVELS: 'contrailsFlightLevels',
  START_DATE: 'contrailsStartDate',
}

// Fonts
// !! This must be kept in sync with `scss/_variables.scss`
export const BODY_FONT_FAMILY = '"neue-haas-grotesk-text", sans-serif'
export const HEADING_FONT_FAMILY = '"Saira", sans-serif'

// Colors
// !! This must be kept in sync with `index.css`
export const COLOR_BLACK = '#000000'
export const COLOR_GRAY = '#424242'
export const COLOR_GRAYLITE = '#e6ecf2'
export const COLOR_GRAYLITE_RGB: TColor = [230, 236, 242]
export const COLOR_TRANSPARENT = '#FFFFFF00'
export const COLOR_WHITE = '#FFFFFF'

// Reviate brand style guide */
export const COLOR_TEAL_LIGHT = '#559BB0'
export const COLOR_TEAL_LIGHT_RGB: TColor = [85, 155, 176]
export const COLOR_TEAL = '#0F6F8A'
export const COLOR_TEAL_RGB: TColor = [15, 111, 138]
export const COLOR_NAVY = '#0D1A42'
export const COLOR_NAVY_RGB: TColor = [13, 26, 66]
export const COLOR_GREEN_DARK = '#73A62A'
export const COLOR_GREEN_LIGHT = '#51C071'
export const COLOR_BLUE_LIGHT = '#34C3EB'
export const COLOR_ORANGE = '#FDB459'
export const COLOR_ORANGE_RGB: TColor = [253, 180, 89]
export const COLOR_RED = '#A47672'

export const INTERSECT_RGB: TColor = [164, 6, 6, 255]
export const NO_INTERSECT_RGB: TColor = [255, 255, 255, 255]
export const COLOR_BBOX: TColor = [130, 130, 130]
export const COLOR_EDITABLE_BBOX_RGB = COLOR_NAVY_RGB
export const COLOR_SETTINGS_TOOLTIP = COLOR_NAVY
export const COLOR_COCIP = COLOR_NAVY
export const COLOR_COCIP_RGB = COLOR_NAVY_RGB
export const G_CONTRAILS_HEX = COLOR_TEAL_LIGHT
export const G_CONTRAILS_RGB = COLOR_TEAL_LIGHT_RGB
export const COLOR_PCR_RGB: TColor = [250, 250, 250]
export const COLOR_PCR_LIGHT_RGB: TColor = [250, 250, 250]
export const FLIGHT_LEVELS = [
  270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410,
  420, 430, 440,
]
export const FLIGHT_LEVEL_STEP_SIZE = FLIGHT_LEVELS[1] - FLIGHT_LEVELS[0]
export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'
export const DATE_FORMAT_WITH_ZONE = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
export const REQUEST_TIMEOUT = 40000

export const ALTITUDE_FACTOR = 3
export const ALTITUDE_POWER = 1.3
export const FEET_PER_METER = 3.28084
export const RADIUS_K = 6373 // mean radius of the earth (km) at 39 degrees from the equator

const TIMEZONE_WIDTH = 360 / 24
const TIMEZONE_HOURS_OFFSET = new Date().getTimezoneOffset() / 60
const LATITUDE_OFFSET = 25
const LONGITUDE_OFFSET = 75
export const INITIAL_MAP_STATE = {
  // very rough estimate of user's location, assuming each timezone is 15 degrees wide
  // and the user is in the northen hemisphere
  latitude: 40,
  longitude: 0 - (TIMEZONE_HOURS_OFFSET * TIMEZONE_WIDTH - TIMEZONE_WIDTH / 2),
  maxPitch: 90,
  pitch: 0,
  bearing: 0,
  zoom: 2.5,
}
const INITIAL_BBOX = {
  N: INITIAL_MAP_STATE.latitude + LATITUDE_OFFSET,
  S: INITIAL_MAP_STATE.latitude - LATITUDE_OFFSET,
  W: INITIAL_MAP_STATE.longitude - LONGITUDE_OFFSET,
  E: INITIAL_MAP_STATE.longitude + LONGITUDE_OFFSET,
}

// if we cared badly enough we could use this to get the timezone name
// Intl.DateTimeFormat().resolvedOptions().timeZone
// and then create a lookup table of coords

export const AIRCRAFT_TYPES = [
  'A20N',
  'A21N',
  'A319',
  'A320',
  'A321',
  'A333',
  'A350',
  'B737',
  'B738',
  'B77W',
  'B789',
]
export const DEFAULTS = {
  AIRCRAFT_TYPE: 'A320',
  BBOX: INITIAL_BBOX,
  EF_THRESHOLD: 1e9,
  FLIGHT_LEVELS: [300, 310] as [number, number],
  FORMAT: 'geojson' as 'geojson',
  GOOGLE_THRESHOLD: undefined, // by default turn off
  INTERIORS: false,
  MET_SOURCE: 'HRES' as 'HRES',
  NUMBER_OF_TIMES: 24,
  VIEW_STATE: INITIAL_MAP_STATE,
}

export const LAYER_OPTIONS: Record<TLayer, string> = {
  cocip: 'High Warming',
  gcontrails: 'G Contrails',
  pcr: 'All Contrails',
  trajectory: 'Trajectory',
}

export const REQUEST_CANCELED = 'Request was canceled by user'
