import React from 'react'

import './attribution.css'

interface LinkProps {
  href: string
  title: string
}

const ExternalLink: React.FC<LinkProps> = ({ href, title }) => (
  <a
    className="link"
    href={href}
    rel="noopener noreferrer"
    target="_blank"
    title={title}
  >
    {title}
  </a>
)

export const Attribution: React.FC = () => {
  return (
    <div className="attribution-container">
      <p className="attribution-content">
        <ExternalLink href="https://contrails.org/terms/" title="Terms" />
      </p>
      <span className="separator">|</span>
      <p className="attribution-content">
        <ExternalLink href="https://contrails.org/privacy/" title="Privacy" />
      </p>
      <span className="separator">|</span>
      <p className="attribution-content">&copy; Breakthrough Energy</p>
    </div>
  )
}
