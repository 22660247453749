import { cocip, ICocipApi } from './cocip'
import { flightPlan, IFlightPlanApi } from './flightPlan'
import { gcontrails, IGContrailsApi } from './gcontrails'
import { pcr, IPcrApi } from './pcr'
import { verticalProfile, IVerticalProfileApi } from './verticalProfile'

interface APIInterface {
  cocip: ICocipApi
  flightPlan: IFlightPlanApi
  gcontrails: IGContrailsApi
  pcr: IPcrApi
  verticalProfile: IVerticalProfileApi
}

export const API: APIInterface = {
  cocip,
  flightPlan,
  gcontrails,
  pcr,
  verticalProfile,
}
