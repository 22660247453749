import React from 'react'
import { Slider } from 'antd'
import type { SliderMarks } from 'antd/es/slider'

import { useSetViewstate, useViewstate } from '../../hooks'
import './BearingSlider.css'

export const BearingSlider = () => {
  const viewState = useViewstate()
  const setViewState = useSetViewstate()

  const marks: SliderMarks = {
    [-180]: { label: <div className="bearing-slider-mark-label">-180°</div> },
    0: { label: <div className="bearing-slider-mark-label">0°</div> },
    180: { label: <div className="bearing-slider-mark-label">180°</div> },
  }

  const handleChange = (bearing: number) => {
    setViewState((currentState) => ({ ...currentState, bearing }))
  }

  return (
    <div className="bearing-slider-container">
      <Slider
        marks={marks}
        max={180}
        min={-180}
        onChange={handleChange}
        style={{ pointerEvents: 'all' }}
        tooltip={{ placement: 'right' }}
        value={viewState.bearing}
      />
      <div className="bearing-slider-label">
        <strong>Bearing</strong>
      </div>
    </div>
  )
}
