import React, { useEffect, useMemo, useState } from 'react'
import { Button, Tooltip } from 'antd'
import { Position } from '@turf/turf'
import { SettingOutlined } from '@ant-design/icons'
import { WebMercatorViewport } from '@deck.gl/core/typed'

import { actionCreators } from '../../state/reducer'
import { Attribution } from '../Attribution'
import { BearingSlider } from '../BearingSlider'
import { buildGeojson } from './utils'
import { buildFlightPath, exaggerateLine } from '../../utils'
import { COLOR_SETTINGS_TOOLTIP, COLOR_BLACK } from '../../constants'
import { ConfigDrawer } from '../ConfigDrawer'
import { DropTarget } from '../DropTarget'
import { FlightLevelLine } from '../FlightLevelLine'
import { FlightPlanModal } from '../FlightPlanModal'
import { LayerToggle } from '../LayerToggle'
import { LoadingSpinner } from '../LoadingSpinner'
import { Logo } from './assets'
import { Map } from '../Map'
import { PitchSlider } from '../PitchSlider'
import { TAggregatedPathData } from '../../types'
import { Timeline } from '../Timeline'
import { useAppContext, useAppUtilities, useSetViewstate } from '../../hooks'
import './MapContainer.css'

export const MapContainer: React.FC = () => {
  const { dispatch, state } = useAppContext()
  const { slicesDownloaded } = useAppUtilities()
  const setViewState = useSetViewstate()

  const [hovered, setHovered] = useState(false)
  const [flightPath, setFlightPath] = useState<TAggregatedPathData>()
  const [dragActive, setDragActive] = useState(false)
  const [coordinates, setCoordinates] = useState<Position[]>()

  const currentData = useMemo(() => {
    const cocipValues = Object.values(state.cocip)
    const pcrValues = Object.values(state.pcr)

    if (cocipValues.length > 0) {
      return state.cocip
    }

    if (pcrValues.length > 0) {
      return state.pcr
    }

    return {}
  }, [state.cocip, state.pcr])

  const viewport = useMemo(() => {
    return new WebMercatorViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, [])

  // recalculate intersections following changes to cocip/pcr or times
  useEffect(() => {
    if (state.downloading) return
    if (!coordinates) return

    const updatedPath = buildFlightPath(
      coordinates,
      currentData,
      state.timeline.times
    )
    setFlightPath(updatedPath)
  }, [state.downloading, currentData, state.timeline.times, coordinates])

  const flightPathPresent = useMemo(() => !!flightPath, [flightPath])

  // enable 'trajectory' option when a flight path gets loaded
  useEffect(() => {
    if (!flightPathPresent) return

    dispatch(actionCreators.addLayerOption('trajectory'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, flightPathPresent])

  // move map to sit over the middle of the path
  useEffect(() => {
    if (!coordinates) return

    const [firstLon, firstLat] = coordinates[0]

    let minLon = firstLon
    let maxLon = firstLon
    let minLat = firstLat
    let maxLat = firstLat

    coordinates.forEach(([lon, lat]) => {
      minLon = Math.min(minLon, lon)
      maxLon = Math.max(maxLon, lon)
      minLat = Math.min(minLat, lat)
      maxLat = Math.max(maxLat, lat)
    })

    const { longitude, latitude, zoom } = viewport.fitBounds(
      [
        [minLon, minLat],
        [maxLon, maxLat],
      ],
      { padding: 100 }
    )

    setViewState((currentState) => ({
      ...currentState,
      latitude,
      longitude,
      zoom,
      transitionDuration: 500,
    }))
  }, [coordinates, setViewState, viewport])

  const handleConfigClick = () => {
    setHovered(false)
    dispatch(actionCreators.setActiveModal('config'))
  }

  const showTooltip = () => {
    if (state.activeModal !== 'none') return false
    if (slicesDownloaded === 0) {
      return !state.downloading
    }
    return hovered
  }
  const tooltipTitle =
    slicesDownloaded === 0
      ? 'Select a domain to request data'
      : 'Configure API Request'
  const tooltipColor =
    slicesDownloaded === 0 ? COLOR_SETTINGS_TOOLTIP : COLOR_BLACK

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { files } = e.dataTransfer

    if (files.length > 0) {
      const file: File = files[0]

      buildGeojson(file, (geojson) => {
        const coords = geojson.geometry.coordinates
        exaggerateLine(coords)
        setCoordinates(coords)

        const path = buildFlightPath(coords, currentData, state.timeline.times)
        setFlightPath(path)

        setDragActive(false)
      })
    }
  }

  return (
    <div
      className="map-container"
      onDragEnter={() => setDragActive(true)}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <DropTarget
        onDragLeave={() => setDragActive(false)}
        visible={dragActive}
      />

      <Map flightPath={flightPath} />

      <div className="top-left">
        <div className="header-row">
          <img alt="logo" className="logo" src={Logo} width="100" />
          <LoadingSpinner />
        </div>
        <FlightPlanModal
          setCoordinates={setCoordinates}
          setFlightPath={setFlightPath}
        />
        <LayerToggle />
      </div>

      <div className="bottom-left">
        <PitchSlider />
        <BearingSlider />
      </div>

      <Timeline />

      <div className="right-side">
        <div className="settings-button">
          <Tooltip
            color={tooltipColor}
            open={showTooltip()}
            placement="left"
            title={tooltipTitle}
          >
            <Button
              icon={<SettingOutlined />}
              onClick={handleConfigClick}
              shape="circle"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            />
          </Tooltip>
        </div>
        <FlightLevelLine />
      </div>
      <Attribution />
      <ConfigDrawer />
    </div>
  )
}
